import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import Star from './Star';
import { Link, useHistory } from 'react-router-dom';
import { Fade, Flip, Roll } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Produse = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const categories = useSelector((state) => state.params.categories);
  const products = useSelector((state) => state.params.products);
  return (
    <S.Produse id='galerie'>
      <h2>{t('products_our')}</h2>
      <p>
        {t('discover_first')} <br />
        {t('discover_second')}
      </p>
      <Fade>
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          className='mySwiper'
          spaceBetween={12}
          slidesPerView={4}
          slidesPerGroup={2}
          autoplay={{
            delay: 2000,
          }}
          breakpoints={{
            346: {
              // width: 576,
              slidesPerView: 2,
            },
            768: {
              // width: 768,
              slidesPerView: 4,
            },
          }}>
          {categories &&
            categories.slice(0, 12).map((elem, i) => (
              <SwiperSlide>
                <div
                  onClick={() => {
                    history.push(`/produse?category=${elem?.category_name}`);
                    window.scrollTo(0, 0);
                  }}>
                  <p className='vezi'>{t('see_products')}</p>
                  <img
                    src={`${elem?.category_path}/${elem?.img_url}`}
                    alt='produs'
                  />
                  <h3>{elem?.category_name}</h3>
                  <p>
                    {t('de_la')} {elem?.price_small} MDL/m<span>2</span>
                  </p>
                  <h5>
                    {products &&
                      products.filter(
                        (prod) => prod?.category === elem?.category_name
                      )?.length}{' '}
                    {t('produse_shortend')}
                  </h5>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </Fade>
      <Fade>
        <div className='other_blocks'>
          {categories.slice(12, 16).map((elem, i) => (
            <div
              onClick={() =>
                history.push(`/produse?category=${elem?.category_name}`)
              }>
              <p className='vezi'>Vezi Produsele</p>
              <img
                src={`${elem?.category_path}/${elem?.img_url}`}
                alt='produs'
              />
              <h3>{elem?.category_name}</h3>
              <p>
                {t('de_la')} {elem?.price_small} MDL/m<span>2</span>
              </p>
              <h5>
                {products &&
                  products.filter(
                    (prod) => prod?.category === elem?.category_name
                  )?.length}{' '}
                {t('produse_shortend')}
              </h5>
            </div>
          ))}
        </div>
      </Fade>
      <Link to='/produse'>{t('vezi_toate_produsele')}</Link>
    </S.Produse>
  );
};

export default Produse;
