import styled from 'styled-components';

export const BgAnimated = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /*! CSS Used from: Embedded */
  @media (max-width: 768px) {
    opacity: 0.7;
  }
  .blob {
    height: 100%;
    width: 100%;
  }
  #blob {
    background-color: #d8f1ed;
    position: relative;
  }
  .scrollanim_fade {
    height: 100%;
    width: 100%;
  }
  * {
    box-sizing: border-box;
  }
  #slideshow {
    transition: opacity 1s ease 0.5s;
  }
  .slideshow {
    height: 110vh;
    opacity: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  @media screen and (max-width: 600px) {
    .slideshow {
      height: 100vh;
    }
  }
  .slideshow .slideshow-inner {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .slideshow .slide,
  .slideshow .slides {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .slideshow .slide {
    background: #000;
    display: none;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease 1s;
  }
  .slideshow .slide.q_current {
    display: block;
  }
  .slideshow .slide.is-loaded {
    opacity: 1;
  }
  .slideshow .slide .image-container,
  .slideshow .slide .image-wrapper {
    background-position: 50%;
    background-size: cover;
    height: 100%;
    image-rendering: optimizeQuality;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .slideshow .slides .slide .image-container .image-wrapper > div {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }
  .reveal_short {
    display: block;
  }
`;
