import { useState } from 'react';
import * as S from './styles';
import Slider from '@mui/material/Slider';
import Steps from './Steps';
import ProductModal from '../ProductModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

const Calculator = ({ productPage }) => {
  const [metri, setMetri] = useState(30);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedDimension, setSelectedDimension] = useState();
  const [metraj, setMetraj] = useState(20);
  const [price, setPrice] = useState(1000);
  const { t, i18n } = useTranslation();

  const categories = useSelector((state) => state.params.categories);
  console.log(productPage, selectedProduct);
  const currentCategory =
    productPage || selectedProduct
      ? categories.filter(
          (elem) =>
            elem?.category_name === productPage?.product?.category ||
            elem?.category_name === selectedProduct?.product?.category
        )?.[0]
      : null;

  useEffect(() => {
    if (selectedProduct) {
      setSelectedDimension(currentCategory?.dimension_1);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedDimension) {
      if (selectedDimension === currentCategory?.dimension_1) {
        setPrice(
          ((metraj * 1000) / parseInt(selectedDimension.split(' x ')[0])) *
            currentCategory?.price_small
        );
      } else if (selectedDimension === currentCategory?.dimension_2) {
        setPrice(
          ((metraj * 1000) / parseInt(selectedDimension.split(' x ')[0])) *
            currentCategory?.price_big
        );
      }
    }
  }, [selectedProduct, metraj, selectedDimension]);

  useEffect(() => {
    if (productPage) {
      setSelectedProduct({
        variety: productPage?.variety,
        product: productPage?.product,
      });
    }
  }, [productPage]);

  console.log(currentCategory);
  return (
    <>
      {openModal && (
        <ProductModal
          onClose={() => setOpenModal(false)}
          onClick={setSelectedProduct}
        />
      )}
      <S.Calculator id='calculator' isRussian={i18next.language === 'ru'}>
        <div>
          <h3>{t('calculator_online')}</h3>
          <div>
            <div>
              {selectedProduct ? (
                <div className='produsul'>
                  <h2>{t('produs')}</h2>
                  <div className='produs'>
                    {!productPage && (
                      <svg
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='close'
                        width={35}
                        height={35}
                        onClick={() => setSelectedProduct()}>
                        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                        <g
                          id='SVGRepo_tracerCarrier'
                          stroke-linecap='round'
                          stroke-linejoin='round'></g>
                        <g id='SVGRepo_iconCarrier'>
                          {' '}
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                            fill='#212121'></path>{' '}
                        </g>
                      </svg>
                    )}
                    <img
                      src={`${selectedProduct?.product?.category_path}/${selectedProduct?.product?.img_url}`}
                      alt='Phomi'
                    />
                    <span>
                      {selectedProduct?.product?.category} -{' '}
                      {selectedProduct?.product?.name}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='produsul' onClick={() => setOpenModal(true)}>
                  <h2>{t('produs')}</h2>
                  <p>{t('choose_product')}</p>
                </div>
              )}
              <div className='dimensiune'>
                <h2>{t('dimension')}</h2>
                <div>
                  {selectedProduct ? (
                    <>
                      <p
                        className={
                          selectedDimension === currentCategory?.dimension_1
                            ? 'active'
                            : ''
                        }
                        onClick={() =>
                          setSelectedDimension(currentCategory?.dimension_1)
                        }>
                        {currentCategory?.dimension_1}
                      </p>
                      {currentCategory?.dimension_2 && (
                        <p
                          className={
                            selectedDimension === currentCategory?.dimension_2
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            setSelectedDimension(currentCategory?.dimension_2)
                          }>
                          {currentCategory?.dimension_2}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className='active'>1200 x 600</p>
                      <p>2400 x 1200</p>
                    </>
                  )}
                </div>
              </div>
              <div className='metrajul'>
                <div className='title'>
                  <h2>{t('metrajul')}</h2>
                  <input
                    type='text'
                    maxLength={5}
                    value={metraj}
                    onChange={(e) =>
                      setMetraj(e.target.value.replace(/[^0-9]/g, ''))
                    }
                  />
                  <p>
                    m<span>2</span>
                  </p>
                </div>
                <div>
                  <Slider
                    aria-label='Temperature'
                    defaultValue={30}
                    valueLabelDisplay='auto'
                    value={metraj}
                    onChange={(e, value) => setMetraj(value)}
                    min={10}
                    max={200}
                    color='secondary'
                  />
                </div>
              </div>
            </div>
            <div className='content'>
              <h4>{t('final_price')}</h4>
              <span>{parseInt(price).toLocaleString()} MDL</span>
              <p>{t('calc_paragraf')}</p>
              <p>{t('calc_para_two')}</p>
              <div>
                <a
                  href='/Catalog Phomi.pdf'
                  download='Catalog Phomi.pdf'
                  target='_blank'>
                  {t('descarca_catag')}
                </a>
                {!productPage && (
                  <button
                    onClick={() => {
                      document
                        .getElementById('contact')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}>
                    {t('solicita_oferta')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </S.Calculator>
      {!productPage && <Steps />}
    </>
  );
};

export default Calculator;
