import styled from 'styled-components';

export const Despre = styled.div`
  margin-top: px;
  @media (max-width: 768px) {
    margin-top: 72px;
  }
  & > div:first-of-type {
    width: 100%;
    height: 550px;
    position: relative;
    @media (max-width: 768px) {
      height: fit-content;
    }
    svg {
      position: absolute;
      max-width: 40px;
      width: 40px;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      user-select: none;
      @media (max-width: 768px) {
        max-width: 30px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
    & > div {
      width: 100% !important;
      height: 550px !important;
      @media (max-width: 768px) {
        height: 350px !important;
      }
      video {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .content {
    max-width: 1170px;
    margin: 32px auto;
    text-align: center;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    h1 {
      position: relative;
      width: fit-content;
      &::after {
        position: absolute;
        width: 100px;
        height: 5px;
        content: '';
        bottom: 5px;
        background: linear-gradient(to right, #212121, #1bb9a4);
        left: 0;
      }
      span {
        font-size: 48px;
        color: blue;
        /* Create a linear gradient from black to blue */
        background: linear-gradient(to right, #212121, #1bb9a4);
        /* Clip the background so it is only behind the text */
        -webkit-background-clip: text;
        background-clip: text;
        /* Make the text color transparent, so the gradient is visible */
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        font-size: 46px;
        margin-bottom: 18px;
        position: relative;
        width: fit-content;
        @media (max-width: 768px) {
          font-size: 38px !important;
          margin-bottom: 8px;
        }
        @media (max-width: 390px) {
          font-size: 35px !important;
        }
      }
    }
    .italic {
      margin-top: 20px;
      text-align: left;
      max-width: 800px;
      width: 100%;
      font-style: italic;
      color: #444444;
    }
    .first_row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 128px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 32px;
      }
      & > div {
        max-width: 65%;
        width: 100%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
      img {
        max-width: 200px;
        @media (max-width: 768px) {
          max-width: 150px;
          margin: 0 auto;
        }
      }
    }
    .bloc {
      display: flex;
      align-items: stretch;
      gap: 32px;
      justify-content: space-between;
      margin-top: 82px;
      @media (max-width: 768px) {
        flex-direction: column;
        &:last-of-type {
          flex-direction: column-reverse;
          margin-top: 32px;
        }
      }
      & > div {
        max-width: 60%;
        width: 100%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        p {
          text-align: left;
          margin-bottom: 20px;
          color: #3e3935;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      & > img {
        max-width: 40%;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
  .beneficii {
    background-image: url(/bg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 64px 0px;
    margin-top: 64px;
    & > div {
      max-width: 1170px;
      margin: 0 auto;
      @media (max-width: 1220px) {
        padding: 0 15px;
      }
      h2 {
        font-size: 38px;
        line-height: 42px;
        margin-bottom: 16px;
        font-weight: 700;
        color: blue;
        -webkit-text-fill-color: transparent;
        position: relative;
        width: fit-content;
        background: linear-gradient(
            to right,
            rgb(33, 33, 33),
            rgb(27, 185, 164)
          )
          text;
      }
      p {
        color: #3e3935;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      & > div {
        margin-top: 32px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;

        & > div {
          max-width: calc(33% - 8px);
          width: 100%;
          padding: 16px;
          border-radius: 8px;
          background: rgba(27, 185, 163, 0.3);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          h3 {
            color: #3e3935;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
            img {
              max-width: 25px;
            }
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .swiper_comp {
    width: 100%;
    max-height: 600px;
    .swiper {
      width: 100%;
      .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background: #1bb9a3;
        opacity: 0.4;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
      .swiper-slide {
        height: auto;
        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
          object-position: center;
          @media (max-width: 768px) {
            height: 250px;
          }
        }
      }
    }
  }
  .history {
    max-width: 1170px;
    margin: 64px auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 92px;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
    h3 {
      font-size: 38px;
      line-height: 42px;
      margin-bottom: 48px;
      font-weight: 700;
      color: blue;
      -webkit-text-fill-color: transparent;
      position: relative;
      width: fit-content;
      background: linear-gradient(to right, rgb(33, 33, 33), rgb(27, 185, 164))
        text;
      position: relative;
      width: fit-content;
      &::after {
        position: absolute;
        width: 100px;
        height: 5px;
        content: '';
        bottom: -5px;
        background: linear-gradient(to right, #212121, #1bb9a4);
        left: 0;
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    img {
      max-width: 40%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    & > div {
      max-width: 60%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        a {
          padding: 12px 24px;
          border: none;
          border-radius: 6px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          &:first-of-type {
            background: #1bb9a4;
          }
          @media (max-width: 768px) {
            width: 260px;
            text-align: center;
          }
          &:last-of-type {
            background: #c0584b;
          }
        }
      }
      p {
        margin-bottom: 24px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
