import styled, { keyframes, css } from 'styled-components';

const helperSlidesInAnimation = css`
  @keyframes helper-slides-in {
    0% {
      top: -100px;
    }

    25% {
      top: 40px;
    }

    100% {
      top: 40px;
    }
  }

  -webkit-animation: helper-slides-in 4s 1; /* Safari 4+ */
  -moz-animation: helper-slides-in 4s 1; /* Fx 5+ */
  -o-animation: helper-slides-in 4s 1; /* Opera 12+ */
  animation: helper-slides-in 4s 1; /* IE 10+, Fx 29+ */
`;
export const Success = styled.p`
  ${helperSlidesInAnimation}
  padding: 12px 16px;
  border-radius: 8px;
  background: #1bb9a4;
  color: #fff !important;
  font-size: 14px;
  padding-right: 60px;
  position: relative;
  margin-left: auto;
  margin-top: -20px;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: top 0.2s, opacity 0.1s;
  z-index: 999999999900000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  br {
    display: none;
  }
  @media (max-width: 768px) {
    max-width: 360px;
    width: 100%;
    font-size: 13px;
    br {
      display: block;
    }
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 25px;
  }
`;
export const Adminka = styled.div``;

export const Login = styled.div`
  min-height: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 20px;
    margin-bottom: 8px;
  }
  input {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  button {
    width: 100px;
    padding: 8px 16px;
    border: none;
    outline: none;
    background: #1bb9a3;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }
  .error {
    font-size: 14px;
    color: red;
    margin-top: 8px;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  & > div {
    padding: 32px;
    background-color: #fff;
    border-radius: 8px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 24px;
      button {
        width: 100px;
        padding: 8px 16px;
        border: none;
        outline: none;
        background: #1bb9a3;
        color: #fff;
        border-radius: 8px;
        cursor: pointer;
        &:last-of-type {
          background: transparent;
          color: #1bb9a3;
          border: 1px solid #1bb9a3;
        }
      }
    }
  }
`;

export const Admin = styled.div`
  max-width: 1170px;
  margin: 32px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 0 15px;
  }
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 32px;
    p {
      padding: 8px 16px;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      border: 1px solid transparent;

      &.active {
        background: #1bb9a3;
        color: #fff;
      }
      &:not(.active) {
        color: #1bb9a3;
        border: 1px solid #1bb9a3;
      }
    }
  }
`;

export const ProductAd = styled.div`
  width: 100%;
  .tabs {
    p {
      padding: 4px 8px;
      font-size: 14px;
    }
  }
  .add_new {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-bottom: 0px;
    margin-top: 32px;
    font-size: 20px;
    font-weight: 300;
    align-items: center;
    cursor: pointer;
    width: 250px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    svg {
      max-width: 30px;
      margin-right: 4px;
      margin-bottom: -2px;
    }
  }
  .filter_products {
    width: 220px;
    @media (max-width: 768px) {
      width: 200px;
      p {
        font-size: 14px;
      }
    }
    div {
      display: flex;
      align-items: center;
      gap: 8px;
      & > svg {
        max-width: 20px;
      }
    }
    .MuiInputBase-root {
      width: 220px;
    }
  }

  .table {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    background: #f7f7f7;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    & > div:not(.MuiInputBase-root) {
      width: 100%;
      padding: 8px 16px;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      display: flex;
      align-items: center;
      gap: 32px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 16px;
        gap: 16px;
      }
      .big_img {
        max-width: 75px;
        border-radius: 8px;
        @media (max-width: 768px) {
          max-width: 100px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: 400;
        color: #3e3935;
        width: 200px;
        @media (max-width: 768px) {
          text-align: center;
          width: fit-content;
        }
      }
      .dimensiuni {
        width: 220px;
        @media (max-width: 768px) {
          width: fit-content;
          text-align: center;
        }
        span {
          background: #cf5b4c;
          color: #3e3935;
          padding: 4px 8px;
          border-radius: 8px;
          color: #fff;
        }
      }
      .preturi {
        span {
          background: #1bb9a3;
          color: #fff;
          padding: 4px 8px;
          border-radius: 8px;
        }
      }
      .icons {
        display: flex;
        margin-left: auto;
        gap: 10px;
        @media (max-width: 768px) {
          margin: 0 auto;
        }
        svg {
          max-width: 20px;
          width: 20px;
          cursor: pointer;
          @media (max-width: 768px) {
            max-width: 30px;
            width: 30px;
          }
        }
      }
      &:first-child {
        border-top: 1px solid #ccc;
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;

export const EditCateg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .categ_choose {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
  & > div {
    & > div {
      max-width: 700px;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;
      position: relative;
      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      h3 {
        font-size: 24px;
        font-weight: 400;
        width: 100%;
      }
      & > div {
        max-width: calc(50% - 16px);
        width: 100%;
        span {
          font-size: 12px;
          opacity: 0.7;
        }
        .image {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-top: 8px;
          p {
            font-size: 14px;
            cursor: pointer;
          }
        }
        img {
          max-width: 80px;
          border-radius: 8px;
        }
        p {
          font-size: 18px;
          font-weight: 300;
        }
        input {
          padding: 8px 16px;
          font-size: 16px;
          border: none;
          outline: none;
          border-bottom: 1px solid #ccc;
          width: 100%;
        }
      }
      .button {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
          margin-left: auto;
          padding: 8px 16px;
          border: none;
          outline: none;
          background: #1bb9a3;
          color: #fff;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const Leads = styled.div`
  max-width: 900px;
  width: 100%;
  .filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 32px auto;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    p {
      padding: 8px 16px;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        padding: 4px 8px;
        font-size: 14px;
        max-width: calc(50% - 8px);
        width: 100%;
      }
      &.active {
        background: #1bb9a3;
        color: #fff;
      }
      &:not(.active) {
        color: #1bb9a3;
        border: 1px solid #1bb9a3;
      }
    }
  }
  .tables {
    margin-top: 32px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 16px;
      gap: 32px;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
      svg {
        margin-left: auto;
        user-select: none;
      }
      p {
        &:first-child {
          width: 350px;
        }
        &:nth-child(2) {
          width: 200px;
        }
        font-size: 14px;
        span {
          padding: 8px 16px;
          border-radius: 8px;
          color: #fff;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          border: 1px solid transparent;
          background: #1bb9a3;
          color: #fff;
          font-weight: 600;
          margin-left: 8px;
          @media (max-width: 768px) {
            padding: 4px 8px;
          }
          &.phone {
            padding: 4px 8px;
            margin-top: 10px;
            padding: 0;
            background: transparent;
            color: #1bb9a3;
            font-size: 20px;
            margin-left: 0;
          }
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
      position: relative;
      svg {
        max-width: 30px;
        width: 30px;
        @media (max-width: 768px) {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
        &.active {
          transform: rotate(180deg);
        }
      }
      .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 32px;
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
        p {
          max-width: calc(50% - 16px);
          width: 100%;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          span {
            background: none !important;
            color: #1bb9a3 !important;
            font-weight: 700 !important;
            font-size: 18px !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
`;
