import { useEffect, useState } from 'react';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCategories,
  setIsLoggedIn,
  setProducts,
} from '../../slices/paramsSlice';
import ProductAd from './productsAd';
import axios from 'axios';
import Leads from './leads';

const Adminka = () => {
  const password = 'AdminAdmin123';
  const [typePassword, setTypePassword] = useState('');
  const loggedin = useSelector((state) => state.params.isLoggedIn);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('leads');
  useEffect(() => {
    axios
      .get('https://server-phomi.onrender.com/api/categories')
      .then((res) => {
        dispatch(setCategories(res.data));
      });
    axios.get('https://server-phomi.onrender.com/api/products').then((res) => {
      dispatch(setProducts(res.data));
    });
  }, []);

  const categories = useSelector((state) => state.params.categories);
  const products = useSelector((state) => state.params.products);
  const login = () => {
    if (typePassword === password) {
      dispatch(setIsLoggedIn(true));
    } else {
      setMessage('Parola Incorecta');
    }
  };
  return (
    <S.Adminka>
      {loggedin ? (
        <S.Admin>
          <h1>Admin panel</h1>
          <div className='tabs'>
            <p
              className={selectedTab === 'leads' ? 'active' : ''}
              onClick={() => setSelectedTab('leads')}>
              Cereri
            </p>
            <p
              className={selectedTab === 'products' ? 'active' : ''}
              onClick={() => setSelectedTab('products')}>
              Produse/Categorii
            </p>
          </div>
          {selectedTab === 'leads' ? (
            <Leads />
          ) : (
            <ProductAd categories={categories} products={products} />
          )}
        </S.Admin>
      ) : (
        <S.Login>
          <p>Introduceti Parola</p>
          <input
            type='password'
            placeholder='*******'
            value={typePassword}
            onChange={(e) => setTypePassword(e?.target?.value)}
          />
          <button onClick={() => login()}>Log in</button>
          {message && <p className='error'>{message}</p>}
        </S.Login>
      )}
    </S.Adminka>
  );
};

export default Adminka;
