import Header from './components/Header';
import Footer from './components/Footer';
import Favorites from './components/Favorites';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  setCategories,
  setFalseProgress,
  setInProgress,
  setProducts,
} from './slices/paramsSlice';
import axios from 'axios';

const FavoritePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setInProgress());
    axios
      .get('https://server-phomi.onrender.com/api/categories')
      .then((res) => {
        dispatch(setCategories(res.data));
      });
    axios.get('https://server-phomi.onrender.com/api/products').then((res) => {
      dispatch(setProducts(res.data));
      dispatch(setFalseProgress());
    });
  }, []);
  return (
    <>
      <Header />
      <Favorites />
      <Footer />
    </>
  );
};

export default FavoritePage;
