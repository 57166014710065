import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Home from './Home';
import ProdusePage from './Produse';
import FavoritePage from './favorite';
import CatacteristiciPage from './caracteristici';
import ProductPage from './productPage';
import Adminka from './components/Adminka';
import Despre from './despre';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <Home {...props} />;
        }}
      />
      <Route
        path='/produse'
        exact
        render={(props) => {
          return <ProdusePage {...props} />;
        }}
      />
      <Route
        path='/favorite'
        exact
        render={(props) => {
          return <FavoritePage {...props} />;
        }}
      />
      <Route
        path='/despre-phomi'
        exact
        render={(props) => {
          return <Despre {...props} />;
        }}
      />
      <Route
        path='/caracteristici-tehnice'
        exact
        render={(props) => {
          return <CatacteristiciPage {...props} />;
        }}
      />
      <Route
        path='/produs/:category/:variety'
        exact
        render={(props) => {
          return <ProductPage {...props} />;
        }}
      />
      <Route
        path='/admin-phomi'
        exact
        render={(props) => {
          return <Adminka {...props} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
