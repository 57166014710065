import Header from './components/Header';
import Footer from './components/Footer';
import DespreComp from './components/Despre';
import Contact from './components/Contact';

const Despre = () => {
  return (
    <>
      <Header whiteFixed />
      <DespreComp />
      <Contact />
      <Footer />
    </>
  );
};

export default Despre;
