import styled from 'styled-components';

export const Produse = styled.div`
  scroll-margin-top: 130px;
  max-width: 1170px;
  margin: 64px auto;
  @media (max-width: 1210px) {
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    scroll-margin-top: 70px;
  }
  h2 {
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 46px;
    margin-bottom: 18px;
    position: relative;
    width: fit-content;
    &::after {
      position: absolute;
      width: 100px;
      height: 5px;
      content: '';
      bottom: 0px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 38px;
    }
    @media (max-width: 390px) {
      font-size: 34px;
    }
  }
  .swiper {
    margin-top: 32px;
    padding-bottom: 48px;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    .swiper-pagination-bullet {
      width: 30px;
      border-radius: 2px;
      height: 2px;
      background: #1bb9a4;
    }
    .swiper-slide {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 6px;
      @media (max-width: 768px) {
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
      div {
        padding-bottom: 12px;
        position: relative;
        transition: 0.3s ease-in-out;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 100.5px);
          content: '';
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: 0.3s ease-in-out;
          border-radius: 4px 4px 0 0;
          @media (max-width: 768px) {
            height: calc(100% - 86px);
          }
        }

        .vezi {
          position: absolute;
          top: calc(50% - 40px);
          left: 50%;
          transform: translate(-50%, -50%);
          width: fit-content;
          white-space: nowrap;
          border: 1px solid #fff;
          z-index: 3;
          color: #fff !important;
          border-radius: 4px;
          padding: 4px 8px;
          opacity: 0;
          transition: 0.3s ease-in-out;
          cursor: pointer;
        }
        &:hover {
          &::after {
            opacity: 1;
          }
          .vezi {
            opacity: 1;
          }
        }
        svg {
          position: absolute;
          max-width: 40px;
          top: 10px;
          right: 10px;
          z-index: 6;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: center;
          border-radius: 4px 4px 0 0;
          @media (max-width: 768px) {
            height: 180px;
          }
        }
        h3 {
          font-size: 20px;
          padding-left: 12px;
          font-weight: 400;
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
        h5 {
          padding-left: 12px;
          margin-top: 3px;
          font-weight: 400;
          color: rgb(87, 87, 87);
        }
        p:not(.vezi) {
          font-size: 22px;
          padding-left: 12px;
          margin-top: 2px;
          font-weight: 700;
          color: blue;
          /* Create a linear gradient from black to blue */
          background: linear-gradient(to right, #212121, #1bb9a4);
          /* Clip the background so it is only behind the text */
          -webkit-background-clip: text;
          background-clip: text;
          /* Make the text color transparent, so the gradient is visible */
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          position: relative;
          width: fit-content;
          font-family: Poppins, sans-serif, sans-serif;
          display: flex;
          font-family: 'Inter', sans-serif;
          span {
            font-size: 12px;
            margin-bottom: 1px;
          }
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .other_blocks {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding: 0;
    }
    & > div {
      max-width: calc(25% - 6px);
      width: 100%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 6px;
      position: relative;
      transition: 0.3s ease-in-out;
      @media (max-width: 768px) {
        max-width: calc(50% - 6px);
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 100.5px);
        content: '';
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: 0.3s ease-in-out;
        border-radius: 4px 4px 0 0;
        @media (max-width: 768px) {
          height: calc(100% - 86px);
        }
      }

      .vezi {
        position: absolute;
        top: calc(50% - 40px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        white-space: nowrap;
        border: 1px solid #fff;
        z-index: 3;
        color: #fff !important;
        border-radius: 4px;
        padding: 4px 8px;
        opacity: 0;
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
        .vezi {
          opacity: 1;
        }
      }
      h5 {
        padding-left: 12px;
        margin-top: 3px;
        font-weight: 400;
        color: rgb(87, 87, 87);
      }
      img {
        max-width: 100%;
        height: 300px;
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 4px 4px 0 0;
        @media (max-width: 768px) {
          height: 180px;
        }
      }
      padding-bottom: 12px;
      position: relative;
      svg {
        position: absolute;
        max-width: 40px;
        top: 10px;
        right: 10px;
        z-index: 9;
      }
      h3 {
        font-size: 20px;
        padding-left: 12px;
        font-weight: 400;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      p:not(.vezi) {
        font-size: 22px;
        padding-left: 12px;
        margin-top: 2px;
        font-weight: 600;
        color: blue;
        /* Create a linear gradient from black to blue */
        background: linear-gradient(to right, #212121, #1bb9a4);
        /* Clip the background so it is only behind the text */
        -webkit-background-clip: text;
        background-clip: text;
        /* Make the text color transparent, so the gradient is visible */
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        position: relative;
        width: fit-content;
        font-family: Poppins, sans-serif, sans-serif;
        display: flex;
        font-family: 'Inter', sans-serif;
        span {
          font-size: 12px;
          margin-bottom: 1px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  & > a {
    text-align: center;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 42px auto;
    border-radius: 8px;
    border: none;
    padding: 8px 16px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #1bb9a4;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #169482;
    }
  }
`;
