import styled from 'styled-components';

export const Whyus = styled.div`
  padding: 48px 0;
  background-image: url(/bg.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-margin-top: 100px;
  @media (max-width: 768px) {
    scroll-margin-top: 40px;
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1210px) {
      padding: 0 15px;
    }
    div {
      & > a {
        border-radius: 8px;
        border: none;
        padding: 8px 16px;
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background: #1bb9a4;
        font-size: 16px;
        display: flex;
        width: fit-content;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        font-size: 14px;
        &:hover {
          background: #169482;
        }
      }
    }
    .mySwiper {
      padding-bottom: 26px;
      margin-top: 16px;
      .swiper-slide div {
        width: 100%;
        width: 100%;
        background: #f7f7f7;
        border-radius: 8px;
        padding: 8px 14px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-bottom: 8px;
        p {
          color: rgb(87, 87, 87);
          font-size: 14px;
          line-height: 18px;
          margin-top: 5px;
        }
        h4 {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .swiper-pagination-bullet {
        width: 30px;
        border-radius: 2px;
        height: 2px;
        background: #1bb9a4;
      }
    }
    h2 {
      font-size: 38px;
      line-height: 47px;
      margin-bottom: 16px;
      font-weight: 700;
      color: blue;
      /* Create a linear gradient from black to blue */
      background: linear-gradient(to right, #212121, #1bb9a4);
      /* Clip the background so it is only behind the text */
      -webkit-background-clip: text;
      background-clip: text;
      /* Make the text color transparent, so the gradient is visible */
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      position: relative;
      width: fit-content;
      &::after {
        position: absolute;
        width: 100px;
        height: 3px;
        content: '';
        bottom: -9px;
        background: linear-gradient(to right, #212121, #1bb9a4);
        left: 0;
      }
      @media (max-width: 1210px) {
        font-size: 42px;
        line-height: 52px;
      }
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
      div {
        max-width: calc(50% - 4px);
        width: 100%;
        height: 250px;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
        @media (max-width: 768px) {
          height: 180px;
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: 0.4s ease-in-out;
          &:hover {
            transform: scale(1.1);
            height: 250px;
            width: 100%;
            overflow: hidden;
          }
        }
      }
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      flex-wrap: wrap;
      gap: 16px;
      .blocks {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;
        & > div {
          max-width: calc(25% - 6px);
          height: auto;
          width: 100%;
          width: 100%;
          background: #f7f7f7;
          border-radius: 8px;
          padding: 8px 14px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          p {
            color: rgb(87, 87, 87);
            font-size: 14px;
            line-height: 18px;
            margin-top: 5px;
          }
          h4 {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      & > div {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;
