import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Fade, Zoom } from 'react-reveal';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { Background, Parallax } from 'react-parallax';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <S.About id='despre-noi'>
        <div>
          <div>
            <div>
              <Zoom>
                <h2>
                  {t('about')} <span>Phomi</span>
                </h2>
                <p>{t('about_first_p')}</p>
                <p>{t('about_second_p')}</p>
                <p>{t('abut_third_p')}</p>
                <div className='blocks'>
                  <div>
                    <h3>
                      <img
                        src='/3-removebg-preview.png'
                        style={{ maxWidth: '25px' }}
                        alt='Universalitate'
                      />
                      {t('universalitate')}
                    </h3>
                    <p>{t('universalitate_desc')}</p>
                  </div>
                  <div>
                    <h3>
                      <img src='/17-removebg-preview.png' alt='usoara' />
                      {t('light_header')}
                    </h3>
                    <p>{t('light_desc')}</p>
                  </div>
                  <div>
                    <h3>
                      <svg
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#1E191A'>
                        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                        <g
                          id='SVGRepo_tracerCarrier'
                          stroke-linecap='round'
                          stroke-linejoin='round'></g>
                        <g id='SVGRepo_iconCarrier'>
                          {' '}
                          <path
                            fill='none'
                            stroke='#1E191A'
                            stroke-width='2'
                            d='M19,15 L23,15 L23,1 L9,1 L9,5 M15,19 L19,19 L19,5 L5,5 L5,9 M1,23 L15,23 L15,9 L1,9 L1,23 L1,23 L1,23 Z'></path>{' '}
                        </g>
                      </svg>
                      {t('collection_vague')}
                    </h3>
                    <p>{t('vague_desc')}</p>
                  </div>
                  <div>
                    <h3>
                      <img
                        src='/13-removebg-preview.png'
                        style={{ maxWidth: '25px' }}
                      />
                      {t('foc_apa_rezistent')}
                    </h3>
                    <p>{t('foc_desc')}</p>
                  </div>
                </div>
              </Zoom>
            </div>

            <div>
              <Fade>
                <img src='/img2.jpeg' alt='Phomi MD' />
              </Fade>
              <Fade>
                <img src='/img2.webp' alt='Phomi MD' />
              </Fade>
            </div>
          </div>
        </div>
      </S.About>
    </>
  );
};

export default About;
