import { Link, useHistory } from 'react-router-dom';
import * as S from './styles';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <S.Footer>
      <div>
        <div>
          <img
            src='/Phomi_Logo_Black_Green_Trasnparent_Background.png'
            alt='logo'
            onClick={() => {
              history.push('/');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
          <p style={{ marginBottom: '12px' }}>{t('footer_desc')}</p>
          <p
            className='line'
            onClick={() =>
              window.open('https://maps.app.goo.gl/vSY3wPcN6pJGd3Bc7', '_blank')
            }>
            Chișinău, str.Puskin 56
          </p>
          <p
            className='line'
            onClick={() => (window.location.href = 'tel:079900999')}>
            0799 00 999
          </p>
        </div>
        <div className='column'>
          <p>{t('gener')}</p>
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            {t('Acasă')}
          </Link>
          <HashLink to='/#despre-noi'>{t('Despre Phomi')}</HashLink>
          <HashLink to='/#de-ce-noi'>{t('de_ce_noi')}</HashLink>
          <HashLink to='/#calculator'>{t('calculator')}</HashLink>
        </div>
        <div className='column'>
          <p>{t('Produse')}</p>
          <Link to='/produse'>{t('categorii')}</Link>
          <Link to='/produse'>{t('toate_produs')}</Link>
        </div>
        <div className='column'>
          <p>{t('aplicatii')}</p>
          <HashLink to='/#calculator'>{t('calculator_pret')}</HashLink>
          <HashLink to='/#contact'>{t('Cerere Online')}</HashLink>
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            Chat Bot
          </Link>
        </div>
      </div>
      <div className='bottom'>2024 Phomi Md - All Rights Reserved</div>
    </S.Footer>
  );
};

export default Footer;
