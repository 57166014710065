import { createSlice } from '@reduxjs/toolkit';

const paramsSlice = createSlice({
  name: 'params',
  initialState: {
    categories: [],
    products: [],
    inProgress: false,
    isLoggedIn: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = [...action.payload];
    },
    setProducts: (state, action) => {
      state.products = [...action.payload];
    },
    setInProgress: (state, action) => {
      state.inProgress = true;
    },
    setFalseProgress: (state, action) => {
      state.inProgress = false;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = true;
    },
  },
});
export const {
  setCategories,
  setProducts,
  setInProgress,
  setFalseProgress,
  setIsLoggedIn,
} = paramsSlice.actions;

export default paramsSlice.reducer;
