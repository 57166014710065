import { useDispatch, useSelector } from 'react-redux';
import * as S from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import Product from '../ProduseHero/product';
import { useTranslation } from 'react-i18next';

const Similar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const category = useSelector((state) => state.params.categories).filter(
    (elem) => elem?.category_name === location?.pathname.split('/')[2]
  )?.[0];

  const product = useSelector((state) => state.params.products).filter(
    (elem) => elem?.category === category.category_name
  );
  console.log(product, 'ejeshaduaus');

  return (
    <S.Similar>
      <h3>{t('Produse Similare')}</h3>
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        className='mySwiper'
        spaceBetween={12}
        slidesPerView={4}
        slidesPerGroup={4}
        autoplay={{
          delay: 2000,
        }}
        breakpoints={{
          346: {
            // width: 576,
            slidesPerView: 2,
          },
          768: {
            // width: 768,
            slidesPerView: 4,
          },
        }}>
        {product.map((elem, index) => (
          <SwiperSlide key={index}>
            <Product
              data={elem}
              categoryData={category}
              onClick={() =>
                history.push(
                  `/produs/${category?.category_name}/${elem?.name.replace(
                    '/',
                    ''
                  )}`
                )
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Similar>
  );
};

export default Similar;
