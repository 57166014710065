import Header from './components/Header';
import Footer from './components/Footer';
import Favorites from './components/Favorites';
import ProductComp from './components/ProductComp';

const ProductPage = () => {
  return (
    <>
      <Header />
      <ProductComp />
      <Footer />
    </>
  );
};

export default ProductPage;
