import { TypeAnimation } from 'react-type-animation';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { products } from '../../products';
import Product from '../ProduseHero/product';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCategories,
  setFalseProgress,
  setInProgress,
  setProducts,
} from '../../slices/paramsSlice';
import Spinner from '../Spinner';

const ProductModal = ({ onClose, onClick }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [showCategory, setShowCategory] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();
  const modal = useRef();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const scrollToTop = () => {
    // Check if the current ref is set
    if (modal.current) {
      console.log(modal.current);
      // Access the immediate child of the current ref
      const scrollableChild = modal.current.firstElementChild;

      if (scrollableChild) {
        // Set scrollTop on the child to scroll it to the top
        scrollableChild.scrollTop = 0;
      }
    }
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setInProgress());
  //   axios.get('https://server-phomi.onrender.com/api/categories').then((res) => {
  //     dispatch(setCategories(res.data));
  //   });
  //   axios.get('https://server-phomi.onrender.com/api/products').then((res) => {
  //     dispatch(setProducts(res.data));
  //     dispatch(setFalseProgress());
  //   });
  // }, []);

  const categories = useSelector((state) => state.params.categories);
  const products = useSelector((state) => state.params.products);

  useEffect(() => {
    scrollToTop();
  }, [selectedCategory]);

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const groupedProducts = products.reduce((acc, product) => {
    // Initialize the category array if not already initialized
    acc[product.category] = acc[product.category] || [];

    // Add the product to the category if it doesn't exceed six items
    if (acc[product.category].length < 6) {
      acc[product.category].push(product);
    }

    return acc;
  }, {});

  // Convert the grouped products object back into an array for rendering
  const limitedProducts = Object.values(groupedProducts).flat();
  const inProgress = useSelector((state) => state.params.inProgress);
  return (
    <S.ProductModal ref={modal} inProgress={inProgress}>
      <OutsideClickHandler onOutsideClick={onClose} className='herereererere'>
        {inProgress && <Spinner />}
        <div>
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='close'
            width={35}
            height={35}
            onClick={onClose}>
            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                fill='#212121'></path>{' '}
            </g>
          </svg>
          <div className='content'>
            <h1 id='scroll_to_here'>
              <TypeAnimation
                sequence={[
                  t('produse_phomi'), // Types 'One'
                  0,
                ]}
                key={i18next.language}
                wrapper='span'
                cursor={true}
                repeat={Infinity}
                style={{
                  fontSize: '48px',
                  display: 'inline-block',
                  lineHeight: '50px',
                  fontWeight: '700',
                  minHeight: '32px',
                }}
              />
            </h1>
            <p className='subcontent'>{t('disponible')}</p>
            <div className='products_wrapper'>
              <div className='sidebar'>
                <h3
                  id='produse_phomi'
                  onClick={() => {
                    windowWidth <= 768 && setShowCategory(!showCategory);
                    windowWidth <= 768 &&
                      document
                        .getElementById('produse_phomi')
                        .scrollIntoView({ behavior: 'smooth' });
                  }}>
                  {t('categs')}{' '}
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={showCategory ? 'active' : ''}
                    width={30}
                    height={30}>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M12 6V18M12 18L7 13M12 18L17 13'
                        stroke='#1bb9a3'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </h3>
                {(showCategory || windowWidth >= 768) && (
                  <div className='category'>
                    {categories.map((elem, i) => (
                      <div
                        onClick={() => {
                          setSelectedCategory(elem?.category_name);
                          windowWidth <= 768 && setShowCategory(false);
                          document
                            .getElementById('produse_phomi')
                            .scrollIntoView({ behavior: 'smooth' });
                        }}
                        className={
                          selectedCategory === elem?.category_name
                            ? 'active'
                            : ''
                        }>
                        <p key={i}>{elem?.category_name}</p>
                        <span>
                          {
                            products.filter(
                              (ie) => ie?.category === elem?.category_name
                            )?.length
                          }
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {windowWidth <= 768 && selectedCategory && (
                <p className='selected_categ'>
                  {selectedCategory}{' '}
                  <svg
                    viewBox='-0.5 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    stroke='#000000'
                    onClick={() => setSelectedCategory()}
                    width={15}
                    height={15}>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M3 21.32L21 3.32001'
                        stroke='#fff'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                      <path
                        d='M3 3.32001L21 21.32'
                        stroke='#fff'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </p>
              )}
              <div className='products'>
                {!selectedCategory ? (
                  <>
                    {limitedProducts.map((variety, i) => (
                      <Product
                        data={variety}
                        categoryData={
                          categories.filter(
                            (elem) => elem?.category_name === variety?.category
                          )?.[0]
                        }
                        onClick={() => {
                          onClick({ product: variety });
                          onClose();
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {products
                      .filter((elem) => elem?.category === selectedCategory)
                      .map((product) => (
                        <Product
                          data={product}
                          categoryData={
                            categories.filter(
                              (elem) =>
                                elem?.category_name === product?.category
                            )?.[0]
                          }
                          onClick={() => {
                            onClick({ product: product });
                            onClose();
                          }}
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </S.ProductModal>
  );
};

export default ProductModal;
