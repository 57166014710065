import { useEffect, useState } from 'react';
import * as S from './styles.js';
import axios from 'axios';
import moment from 'moment';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [selectedCateg, setSelectedCateg] = useState('all');
  const [openLead, setOpenLead] = useState();
  useEffect(() => {
    axios
      .get('https://server-phomi.onrender.com/api/leads')
      .then((res) => setLeads(res?.data));
  }, []);
  return (
    <S.Leads>
      <div className='filters'>
        <p
          onClick={() => setSelectedCateg('all')}
          className={selectedCateg === 'all' ? 'active' : ''}>
          Toate Cererile
        </p>
        <p
          onClick={() => setSelectedCateg('ChatBot')}
          className={selectedCateg === 'ChatBot' ? 'active' : ''}>
          Chat Bot
        </p>
        <p
          onClick={() => setSelectedCateg('Cerere Online')}
          className={selectedCateg === 'Cerere Online' ? 'active' : ''}>
          Cerere Online
        </p>
        <p
          onClick={() => setSelectedCateg('Produs')}
          className={selectedCateg === 'Produs' ? 'active' : ''}>
          Cerere Produs
        </p>
      </div>
      <div className='tables'>
        {selectedCateg === 'all' ? (
          <>
            {leads.map((elem) => (
              <div>
                <p>
                  Crearea Cererii:{' '}
                  <span>{moment(elem?.date_created).format('LLL')}</span>
                </p>
                <p>
                  Sursa: <span>{elem?.source}</span>
                </p>
                <p>
                  Telefon: <br />
                  <span className='phone'>
                    {elem?.data?.phone ||
                      elem?.data?.Telefon ||
                      elem?.data?.telefon}
                  </span>
                </p>
                {elem?.source !== 'ChatBot' && (
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={openLead === elem?.lead_id ? 'active' : ''}
                    onClick={() =>
                      openLead === elem?.lead_id
                        ? setOpenLead()
                        : setOpenLead(elem?.lead_id)
                    }>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z'
                        fill='#5b4d4d'></path>{' '}
                    </g>
                  </svg>
                )}
                {openLead === elem?.lead_id && (
                  <div className='content'>
                    {elem?.data?.Nume && (
                      <p>
                        Nume: <span>{elem?.data?.Nume}</span>
                      </p>
                    )}
                    {elem?.data?.Prenume && (
                      <p>
                        Prenume: <span>{elem?.data?.Prenume}</span>
                      </p>
                    )}
                    {elem?.data?.Email && (
                      <p>
                        Email: <span>{elem?.data?.Email}</span>
                      </p>
                    )}
                    {elem?.data?.['Produs de Referinta'] && (
                      <p>
                        Produs De Referinta:{' '}
                        <span>{elem?.data?.['Produs de Referinta']}</span>
                      </p>
                    )}
                    {elem?.data?.Telefon && (
                      <p>
                        Telefon: <span>{elem?.data?.Telefon}</span>
                      </p>
                    )}
                    {elem?.data?.Mesaj && (
                      <p>
                        Mesaj: <span>{elem?.data?.Mesaj}</span>
                      </p>
                    )}
                    {elem?.data?.produs && (
                      <p>
                        Produs: <span>{elem?.data?.produs}</span>
                      </p>
                    )}
                    {elem?.data?.phone && (
                      <p>
                        Telefon: <span>{elem?.data?.phone}</span>
                      </p>
                    )}
                    {elem?.data?.mesaj && (
                      <p>
                        Mesaj: <span>{elem?.data?.mesaj}</span>
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            {leads
              .filter((elem) => elem?.source === selectedCateg)
              .map((elem) => (
                <div>
                  <p>
                    Crearea Cererii:{' '}
                    <span>{moment(elem?.date_created).format('LLL')}</span>
                  </p>
                  <p>
                    Sursa: <span>{elem?.source}</span>
                  </p>
                  <p>
                    Telefon: <br />
                    <span className='phone'>
                      {elem?.data?.phone ||
                        elem?.data?.Telefon ||
                        elem?.data?.telefon}
                    </span>
                  </p>
                  {elem?.source !== 'ChatBot' && (
                    <svg
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={openLead === elem?.lead_id ? 'active' : ''}
                      onClick={() =>
                        openLead === elem?.lead_id
                          ? setOpenLead()
                          : setOpenLead(elem?.lead_id)
                      }>
                      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                      <g
                        id='SVGRepo_tracerCarrier'
                        stroke-linecap='round'
                        stroke-linejoin='round'></g>
                      <g id='SVGRepo_iconCarrier'>
                        {' '}
                        <path
                          d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z'
                          fill='#5b4d4d'></path>{' '}
                      </g>
                    </svg>
                  )}
                  {openLead === elem?.lead_id && (
                    <div className='content'>
                      {elem?.data?.Nume && (
                        <p>
                          Nume: <span>{elem?.data?.Nume}</span>
                        </p>
                      )}
                      {elem?.data?.Prenume && (
                        <p>
                          Prenume: <span>{elem?.data?.Prenume}</span>
                        </p>
                      )}
                      {elem?.data?.Email && (
                        <p>
                          Email: <span>{elem?.data?.Email}</span>
                        </p>
                      )}
                      {elem?.data?.['Produs de Referinta'] && (
                        <p>
                          Produs De Referinta:{' '}
                          <span>{elem?.data?.['Produs de Referinta']}</span>
                        </p>
                      )}
                      {elem?.data?.Telefon && (
                        <p>
                          Telefon: <span>{elem?.data?.Telefon}</span>
                        </p>
                      )}
                      {elem?.data?.Mesaj && (
                        <p>
                          Mesaj: <span>{elem?.data?.Mesaj}</span>
                        </p>
                      )}
                      {elem?.data?.produs && (
                        <p>
                          Produs: <span>{elem?.data?.produs}</span>
                        </p>
                      )}
                      {elem?.data?.phone && (
                        <p>
                          Telefon: <span>{elem?.data?.phone}</span>
                        </p>
                      )}
                      {elem?.data?.mesaj && (
                        <p>
                          Mesaj: <span>{elem?.data?.mesaj}</span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </>
        )}
      </div>
    </S.Leads>
  );
};

export default Leads;
