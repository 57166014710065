import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import Box from './Box';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const FaqComponent = () => {
  const { t, i18n } = useTranslation();

  const faq = [
    {
      question: t('faq_q_1'),
      answ: t('q_1_a'),
    },
    {
      question: t('q_2_q'),
      answ: t('q_2_a'),
    },
    {
      question: t('q_3_q'),
      answ: t('q_3_a'),
    },
    {
      question: t('q_4_q'),
      answ: t('q_4_a'),
    },
    {
      question: t('q_5_q'),
      answ: t('q_5_a'),
    },
    {
      question: t('q_6_a'),
      answ: t('q_6_q'),
    },
  ];

  return (
    <S.FaqComponent isRussian={i18next.language === 'ru'}>
      <h2>{t('intrebari_frecvente')}</h2>
      <p>{t('intrebari_desc')}</p>
      <div className='column'>
        <Fade left>
          <div>
            {faq?.slice(0, 3).map((e, i) => (
              <>
                <Box question={e?.question} answer={e?.answ} />
              </>
            ))}
          </div>
        </Fade>
        <Fade right>
          <div>
            {faq?.slice(3, 10).map((e, i) => (
              <>
                <Box question={e?.question} answer={e?.answ} />
              </>
            ))}
          </div>
        </Fade>
      </div>
    </S.FaqComponent>
  );
};

export default FaqComponent;
