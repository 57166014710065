import styled from 'styled-components';

export const Calculator = styled.div`
  /* background-image: url(/bg.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  padding: 72px 0;
  scroll-margin-top: 120px;
  @media (max-width: 768px) {
    scroll-margin-top: 40px;
  }
  @media (max-width: 768px) {
    padding: 56px 0;
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1210px) {
      padding: 0 15px;
    }
    & > div:first-of-type {
      margin-top: 48px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 48px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
      }
      & > div {
        max-width: calc(50% - 24px);
        width: 100%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        h2 {
          font-size: 28px;
          font-weight: 500;
          color: #3e3935;
          @media (max-width: 1210px) {
            font-size: 24px;
          }
        }

        .produs {
          width: 100%;
          padding: 10px;
          border: 1px dashed #1bb9a4;
          border-radius: 6px;
          margin-top: 6px;
          margin-bottom: 20px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          transition: 0.3s ease-in-out;
          display: flex;
          align-items: center;
          gap: 24px;
          position: relative;
          svg {
            max-width: 26px;
            position: absolute;
            top: 0px;
            right: 4px;
            cursor: pointer;
          }
          img {
            max-width: 200px;
            height: 70px;
            border-radius: 4px;
          }
          span {
            font-size: 20px;
            font-weight: 400;
            color: rgb(62, 57, 53);
          }
          &:hover {
            box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
            cursor: pointer;
          }
        }
        .produsul {
          width: 100%;
          p {
            width: 100%;
            padding: 16px;
            border: 1px dashed #1bb9a4;
            border-radius: 6px;
            margin-top: 6px;
            margin-bottom: 20px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transition: 0.3s ease-in-out;
            &:hover {
              box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
              cursor: pointer;
            }
          }
        }

        .dimensiune {
          & > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            margin-top: 6px;
            margin-bottom: 20px;
            p {
              max-width: calc(50% - 8px);
              width: 100%;
              text-align: center;
              padding: 10px 12px;
              border: 1px solid #1bb9a4;
              border-radius: 6px;
              transition: 0.3s ease-in-out;
              &:not(.active):hover {
                box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
                cursor: pointer;
              }
              &.active {
                background: #1bb9a4;
                color: #fff;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                cursor: pointer;
              }
            }
          }
        }
        .metrajul {
          .title {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 10px;
            input {
              padding: 6px 12px;
              font-size: 16px;
              width: 80px;
              border: 1px solid #dfe3e9;
              outline: none;
              border-radius: 6px;
            }
            p {
              margin-left: -6px;
              span {
                position: relative;
                top: -8px;
                font-size: 10px;
              }
            }
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        h4 {
          font-size: 28px;
          font-weight: 500;
          color: #3e3935;
        }
        span {
          font-size: 38px;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          display: block;
          margin-bottom: 12px;
        }
        p {
          margin-top: 10px;
          color: rgb(87, 87, 87);
        }
        & > div {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 32px;
          button,
          a {
            padding: 12px 24px;
            border: none;
            border-radius: 6px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            @media (max-width: 768px) {
              ${({ isRussian }) => isRussian && `font-size: 14px;`}
            }
          }
          a {
            background: #1bb9a4;
            line-height: 18px;
            &:hover {
              background: #169482;
            }
          }
          button {
            background: #c0584b;
            &:hover {
              background: #943f34;
            }
          }
        }
      }
    }
  }

  h3 {
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 46px;
    margin-bottom: 18px;
    position: relative;
    width: fit-content;
    &::after {
      position: absolute;
      width: 100px;
      height: 5px;
      content: '';
      bottom: 0px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 34px;
    }
  }
  .MuiSlider-track {
    background: linear-gradient(to right, #212121, #1bb9a4) !important;
    border: none !important;
  }
  .MuiSlider-root {
    height: 6px !important;
  }
  .MuiSlider-rail {
    background: linear-gradient(to right, #212121, #1bb9a4) !important;
    border: none !important;
  }
  .MuiSlider-thumb {
    background: #1bb9a4 !important;
    &:hover {
      box-shadow: 0px 0px 0px 8px rgba(61, 153, 119, 0.16) !important;
    }
  }
  .Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px rgba(61, 153, 119, 0.16) !important;
  }
`;

export const Steps = styled.div`
  margin-top: 96px;
  max-width: 100vw;
  overflow: hidden;
  margin-bottom: 128px;
  @media (max-width: 768px) {
    display: none;
  }
  .container {
    max-width: 1170px;
    margin: 0 auto;
  }
  /*! CSS Used from: https://leasing.md/wp-content/themes/maib/style.css?ver=1.2 ; media=all */
  @media all {
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  }
  /*! CSS Used from: https://leasing.md/wp-content/themes/maib/css/bootstrap.css?ver=6.4.2 ; media=all */
  @media all {
    *,
    ::before,
    ::after {
      box-sizing: border-box;
    }
    .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .col-6,
    .col-md-3 {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .col-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 768px) {
      .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
    @media print {
      *,
      ::before,
      ::after {
        text-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
  /*! CSS Used from: https://leasing.md/wp-content/themes/maib/css/stylea.css?ver=57.5 ; media=all */
  @media all {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #253746;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #00503b;
    }
    .obtii {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #1bb9a4;
      border: 5px solid #fff;
      box-shadow: 0 0 0 2px #1bb9a4;
      transition: all 0.2s ease-in;
      position: relative;
    }
    .obtii:hover {
      background-color: #169482;
      box-shadow: 0 0 0 5px #169482;
      transition: all 0.2s ease-in;
    }
    .row-obtii span {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 600;
    }
    .row-obtii .text {
      margin: 30px 0 0 0px;
      transition: all 0.5s ease-in;
    }
    .links {
      background: linear-gradient(
        #ffffff 0%,
        #ffffff 49%,
        #1bb9a4 50%,
        #1bb9a4 51%,
        #ffffff 52%,
        #ffffff 100%
      );
    }
    .row-obtii {
      padding-top: 30px;
      margin-right: 0px;
      margin-left: 0px;
    }
    .row-obtii [class^='col-'],
    .row-obtii [class*=' col-'] {
      padding-left: 0;
      padding-right: 0;
    }
    .row-obtii .col-6:hover .obtii,
    .row-obtii .col-6:hover .text {
      transition: all 0.5s ease-in;
      transform: scale(1.2);
    }
  }
`;
