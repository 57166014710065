import styled from 'styled-components';

export const InfoSection = styled.div`
  background-image: url(/bg.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 64px 0;
  & > div {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1000px) {
      padding: 0 10px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
    & > div:last-of-type {
      max-width: 40%;
      height: 504px !important;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 80% !important;
        height: auto !important;
      }
      & > div {
        max-width: 100%;
        height: 504px !important;
        width: 100% !important;
        @media (max-width: 768px) {
          max-width: 100% !important;
          height: auto !important;
        }
      }
    }
    video {
      width: 100%;
      border-radius: 4px;
      height: auto;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    & > div:first-of-type {
      padding: 24px;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin-right: -16px;
      z-index: 2;
      @media (max-width: 768px) {
        margin-right: 0;
      }
      h2 {
        font-size: 38px;
        line-height: 42px;
        margin-bottom: 16px;
        font-weight: 500;
        color: blue;
        /* Create a linear gradient from black to blue */
        background: linear-gradient(to right, #212121, #1bb9a4);
        /* Clip the background so it is only behind the text */
        -webkit-background-clip: text;
        background-clip: text;
        /* Make the text color transparent, so the gradient is visible */
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        position: relative;
        width: fit-content;
        &::after {
          position: absolute;
          width: 100px;
          height: 3px;
          content: '';
          bottom: -7px;
          background: linear-gradient(to right, #212121, #1bb9a4);
          left: 0;
        }
        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      p {
        margin-bottom: 10px;
        font-size: 18px;
        color: rgb(87, 87, 87);
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      a {
        border-radius: 8px;
        border: none;
        padding: 8px 16px;
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background: #1bb9a4;
        font-size: 16px;
        margin-top: 16px;
        display: flex;
        width: fit-content;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        &:hover {
          background: #169482;
        }
      }
    }
  }
`;
