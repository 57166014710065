import { MoonLoader } from 'react-spinners';
import styled from 'styled-components';

const SpinnerComp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  z-index: 999;
`;

const Spinner = () => {
  return (
    <SpinnerComp>
      <MoonLoader color='#36d7b7' />
    </SpinnerComp>
  );
};

export default Spinner;
