import styled, { keyframes, css } from 'styled-components';

const helperSlidesInAnimation = css`
  @keyframes helper-slides-in {
    0% {
      top: -100px;
    }

    25% {
      top: 40px;
    }

    100% {
      top: 40px;
    }
  }

  -webkit-animation: helper-slides-in 4s 1; /* Safari 4+ */
  -moz-animation: helper-slides-in 4s 1; /* Fx 5+ */
  -o-animation: helper-slides-in 4s 1; /* Opera 12+ */
  animation: helper-slides-in 4s 1; /* IE 10+, Fx 29+ */
`;
export const Success = styled.p`
  ${helperSlidesInAnimation}
  padding: 12px 16px;
  border-radius: 8px;
  background: #1bb9a4;
  color: #fff !important;
  font-size: 14px;
  padding-right: 60px;
  position: relative;
  margin-left: auto;
  margin-top: -20px;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: top 0.2s, opacity 0.1s;
  z-index: 999999999900000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  br {
    display: none;
  }
  @media (max-width: 768px) {
    max-width: 360px;
    width: 100%;
    font-size: 13px;
    br {
      display: block;
    }
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 25px;
  }
`;

export const Hero = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 50px;
  }
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures that the video covers the space without stretching */
    z-index: -1; /* This puts the video behind any content */
  }

  .content {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 82px;
    @media (max-width: 1220px) {
      padding: 0 15px;
      gap: 48px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    h1 {
      font-size: 48px;
      line-height: 50px;
      color: #212121;
      margin-bottom: 18px;
      font-weight: 500;
      text-shadow: 1px 2px 3px white;
      @media (max-width: 1000px) {
        font-size: 38px !important;
        line-height: 40px !important;
      }
      @media (max-width: 880px) {
        min-height: 120px;
      }
      @media (max-width: 768px) {
        min-height: unset !important;
        ${({ isRussian }) => isRussian && `font-size: 30px !important;`}
      }
      @media (max-width: 412px) {
        font-size: 32px !important;
      }
    }
    p {
      color: rgb(33, 33, 33);
    }
    & > div:first-child {
      max-width: 45%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 18px;
      a {
        font-size: 14px;
        border-radius: 8px;
        border: none;
        padding: 8px 16px;
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: 0.3s ease-in-out;
        &:first-child {
          background: #1bb9a4;
          &:hover {
            cursor: pointer;
            background: #169482;
          }
        }
        &:last-child {
          background: rgb(33, 33, 33);
          color: #fff;
          &:hover {
            background: #3e3935;
            cursor: pointer;
          }
        }
      }
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const QuestionBlock = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: 60%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media (max-width: 768px) {
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    .small_end {
      font-size: 16px !important;
    }
  }
  .explore {
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    background: #c0584b;
    color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background: #1bb9a4;
    display: flex;
    margin-left: auto;
    &:hover {
      cursor: pointer;
      background: #169482;
    }
  }
  .button_group {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
    button {
      margin-left: unset !important;
    }
    button:first-of-type {
      background: #c0584b;
      &:hover {
        background: #943f34;
      }
    }
  }
  .form {
    input,
    textarea {
      width: 100%;
      border: none;
      transition: 0.3s ease-in-out;
      padding-bottom: 8px;
      padding-top: 12px;
      padding: 12px 20px;
      border-radius: 8px;
      margin-top: 6px;
      background: #fff;
      resize: none;
      font-family: inherit;
      font-size: 16px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      &:hover,
      &:active,
      &:focus-visible,
      &:focus-within {
        border: none;
        outline: none;
      }
    }
    button {
      display: flex;
    }
    button {
      padding: 8px 24px;
      border: none;
      border-radius: 8px;
      background: #c0584b;
      color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin-top: 16px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      background: #1bb9a4;
      margin-left: auto;
      font-size: 14px;
      font-weight: 400;
      &:hover {
        cursor: pointer;
        background: #169482;
      }
    }
  }
  h2 {
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: 400;
    color: rgb(87, 87, 87);
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
  h3 {
    font-size: 28px !important;
    line-height: 38px !important;
    padding: 15px !important;
    border-radius: 18px !important;
    background: #f7f7f7;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    color: #3e3935 !important;
    @media (max-width: 768px) {
      font-size: 20px !important;
      line-height: 24px !important;
    }
    span {
      font-size: 30px !important;
      line-height: 38px !important;
      color: #3e3935 !important;
      @media (max-width: 768px) {
        font-size: 20px !important;
        line-height: 24px !important;
        ${({ isRussian }) => isRussian && `font-size: 18px !important;`}
        min-height: 48px !important;
      }
      @media (max-width: 418px) {
        font-size: 19px !important;
      }
      @media (max-width: 402px) {
        font-size: 18px !important;
      }
      @media (max-width: 386px) {
        font-size: 17px !important;
      }
    }
    position: relative;
    svg {
      max-width: 50px;
      width: 40px;
      position: absolute;
      bottom: -22px;
      transform: rotate(200deg);
      left: 19px;
    }
    /* &:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
      right: auto;
      top: auto;
      bottom: -30px;
      border: 15px solid;
      border-color: #f7f7f7 transparent transparent #f7f7f7;
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 38px;
      right: auto;
      top: auto;
      bottom: -20px;
      border: 12px solid;
      border-color: #f7f7f7 transparent transparent #f7f7f7;
    } */
    @media (max-width: 980px) {
      font-size: 28px;
    }
    @media (max-width: 889px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (max-width: 400px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  & > div:not(.form) {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    animation: ${fadeIn} 1s ease-in-out forwards;
    margin-left: auto;
    justify-content: flex-end;
    button {
      border-radius: 8px;
      border: none;
      padding: 8px 24px;
      transition: 0.3s ease-in-out;
      &:first-child {
        background-color: #1bb9a4;
        color: #fff;
        &:hover {
          background: #169482;
          cursor: pointer;
        }
      }
      &:last-child {
        background: transparent;
        border: 1px solid #1bb9a4;
        transition: all 0.3s ease-in-out;
        &:hover {
          border-color: #169482;
          color: #169482;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          cursor: pointer;
        }
      }
    }
  }
`;
