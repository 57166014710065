import styled, { keyframes } from 'styled-components';

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const MobileHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99999;
  transition: 0.3s ease-in-out;
  &.scrolled {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  ${({ whiteFixed }) =>
    whiteFixed &&
    ` background-color: white !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;`}
  .top {
    padding-top: 4px;
    padding-bottom: 0;
    margin-bottom: -3px;
    .phone {
      justify-content: flex-end;
    }
    .favss {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        width: 15px;
      }
    }
    a {
      color: #212121;
    }
  }
  .menu_mobile {
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    background: #fff;
    z-index: -1;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 80px 20px;
    animation: ${slideInFromRight} 0.3s ease-out forwards;
    & > div {
      display: flex;
      flex-direction: column;
      a {
        font-size: 18px;
        margin-bottom: 12px;
        color: #3e3935;
      }
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    @media (max-width: 390px) {
      padding: 10px 10px;
    }
    .logo {
      img {
        max-width: 150px;
        @media (max-width: 445px) {
          max-width: 100px;
          margin-bottom: -6px;
        }
      }
    }
    .phone {
      display: flex;
      align-items: center;
      width: 260px;
      gap: 4px;
      margin-left: auto;
      @media (max-width: 480px) {
        width: 220px;
      }
      svg {
        max-width: 40px;
        margin-bottom: -2px;
        @media (max-width: 768px) {
          height: 25px;
          max-width: 30px;
        }
      }
      p {
        font-family: 'Inter', sans-serif;
        font-size: 27px;
        color: #1bb9a3;
        font-weight: 800;
        letter-spacing: 2px;
        @media (max-width: 930px) {
          font-size: 20px;
          text-shadow: 1px 2px 2px white;
        }
      }
    }
  }
  .block_individual {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 15px;
    transition: 0.3s ease-in-out;
    flex-direction: row !important;
    width: 100%;
    opacity: 0.7;
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
    img {
      max-width: 25px;
      width: 100%;
    }
    p {
      font-size: 16px;
    }
  }

  .blocks {
    display: flex;
    align-items: center;
    gap: 16px;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    width: 100%;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 24px;
    &.langs {
      margin-bottom: 0;
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 46px;
      padding: 10px 0;
      p {
        font-size: 18px;
        &.active {
          color: #1bb9a3;
        }
      }
    }
    & > a {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      width: 200px;
      margin-bottom: 0 !important;
      p {
        font-size: 16px;
      }
      &:last-child {
        background: #fff;
        color: #1bb9a4;
        width: 210px;
        padding: 1px 8px;
        border: 1px solid #1bb9a4;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.3s ease-in-out;
        svg {
          transition: 0.3s ease-in-out;
        }
        &:hover {
          cursor: pointer;
          border-color: #169482;
          color: #169482;
          svg {
            fill: #169482;
            stroke: #169482;
          }
        }
        p {
          font-weight: 500;
          font-size: 15px !important;
        }
      }
      &:first-child {
        background: #1bb9a4;
        padding: 3px 8px;
        width: 210px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.3s ease-in-out;
        padding: 1px 8px;
        &:hover {
          background: #169482;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          cursor: pointer;
        }
        svg {
          padding: 3px 0;
        }
        p {
          color: #fff;
          font-size: 15px !important;
        }
      }
      svg {
        max-width: 40px;
      }
    }
  }
  .css-1tei9w9 {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: normal;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: var(--chakra-lineHeights-7);
    border-radius: var(--chakra-radii-md);
    font-weight: var(--chakra-fontWeights-semibold);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    height: auto;
    min-width: var(--chakra-sizes-10);
    font-size: 20px;
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    padding: 0px;
    text-align: left;
    color: var(--chakra-colors-purple-500);
    background-color: var(--chakra-colors-transparent);
    border: none;
    margin-bottom: -14px;
    width: 30px;
    height: 30px;
    margin-left: -6px;
  }
  .css-1tei9w9:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: var(--chakra-shadows-none);
  }
  .css-1tei9w9:hover:disabled {
    background: initial;
  }
  .css-1wh2kri {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
  .css-1pvzqor {
    position: relative;
    width: 30px;
    height: 3px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    border-width: 0px;
    background-color: var(--chakra-colors-transparent);
    transition: border-width 0.1s linear 0s;
    transform: translateY(-10px);
  }
  .css-1pvzqor::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    transition: transform 0.2s linear 0s;
    transform: rotate(-45deg) translateX(-5px) translateY(6px);
  }
  .css-1pvzqor::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    transition: transform 0.2s linear 0s;
    transform: rotate(45deg) translateX(2px) translateY(-6px);
    transform-origin: left center;
  }
  .css-za1uc2 {
    position: relative;
    width: 33px;
    height: 3px;
    border: var(--chakra-borders-none);
    border-top: solid;
    border-color: none;
    border-width: 0;
    background-color: var(--chakra-colors-transparent);
    -webkit-transition: border-width 0.1s linear;
    transition: border-width 0.1s linear;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .css-za1uc2::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 33px;
    border-top: 2px solid;
    border-color: var(--chakra-colors-violet-500);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    -webkit-transform: translateY(4px);
    -moz-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
  }
  .css-za1uc2::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top: 2px solid;
    border-color: var(--chakra-colors-violet-500);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    transform-origin: left;
  }
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 99;
  .favorite {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #1bb9a4 !important;
    svg {
      max-width: 25px;
      width: 25px;
      height: 25px;
      margin-right: 8px;
      margin-top: -2px;
    }
  }
  .lang {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(87, 87, 87);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      color: #212121;
    }
    svg {
      max-width: 20px;
      width: 20px;
      height: 20px;
      margin-top: -2px;
    }
  }
  .lang_div {
    position: absolute;
    right: -30px;
    background: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    bottom: -64px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    p {
      color: rgb(87, 87, 87);
      font-size: 18px;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #212121;
        cursor: pointer;
      }
      &.active {
        color: #1bb9a4;
        &:hover {
          color: #1bb9a4;
        }
      }
    }
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    padding: 15px 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 1170px) {
      padding: 15px 15px 5px;
    }
    img {
      max-width: 150px;
    }
    .phone {
      display: flex;
      align-items: center;
      width: 260px;
      gap: 6px;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      svg {
        max-width: 40px;
        margin-bottom: -4px;
        path {
          transition: 0.3s ease-in-out;
        }
      }
      p {
        font-family: 'Inter', sans-serif;
        font-size: 27px;
        color: #1bb9a4;
        font-weight: 800;
        letter-spacing: 2px;
        transition: 0.3s ease-in-out;

        @media (max-width: 930px) {
          font-size: 20px;
        }
      }
      &:hover {
        p {
          cursor: pointer;
          color: #169482 !important;
        }
        svg g path {
          fill: #169482 !important;
        }
      }
    }
    .blocks {
      display: flex;
      align-items: center;
      gap: 16px;
      & > a {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        &:last-child {
          background: #fff;
          color: #1bb9a4;
          padding: 3px 16px 3px 6px;
          border: 1px solid #1bb9a4;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          transition: 0.3s ease-in-out;
          svg {
            transition: 0.3s ease-in-out;
          }
          &:hover {
            cursor: pointer;
            border-color: #169482;
            color: #169482;
            svg {
              fill: #169482;
              stroke: #169482;
            }
          }
          p {
            font-weight: 500;
          }
        }
        &:first-child {
          background: #1bb9a4;
          padding: 3px 16px 3px 6px;
          /* width: 210px; */
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          transition: 0.3s ease-in-out;
          &:hover {
            background: #169482;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            cursor: pointer;
          }
          p {
            color: #fff;
          }
        }
        svg {
          max-width: 40px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .bottom_links {
    padding-top: 0;
    justify-content: flex-start;
    gap: 42px;
    padding-bottom: 15px;
    a {
      color: rgb(87, 87, 87);
      position: relative;
      &::after {
        position: absolute;
        content: '';
        bottom: -3px;
        left: 0;
        width: 0;
        height: 2px;
        background: #1bb9a4;
        transition: 0.3s ease-in-out;
      }
      transition: 0.3s ease-in-out;
      &:hover {
        color: #212121;
        &::after {
          width: 100%;
        }
      }
    }
  }
`;
