import { Link } from 'react-router-dom';
import * as S from './styles';
import ReactPlayer from 'react-player';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const InfoSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <S.InfoSection>
      <div>
        <Fade left>
          <div>
            <h2>
              {t('we_wait_for')} <br />
              {t('showroom_our')}
            </h2>
            <p>{t('showroom_first_p')}</p>
            <p>{t('showroom_second_p')}</p>
            <Link
              to='#'
              onClick={() => {
                document
                  .getElementById('contact')
                  .scrollIntoView({ behavior: 'smooth' });
              }}>
              {t('complete_cerere')}
            </Link>
          </div>
        </Fade>
        <Fade right className='right'>
          <ReactPlayer url='/Fabiola.mp4' controls />
        </Fade>
      </div>
    </S.InfoSection>
  );
};

export default InfoSection;
