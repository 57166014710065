import Header from './components/Header';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import ScrollToTop from './TopScroll';
import { Widget } from '@uploadcare/react-widget';
import './i18n';
import axios from 'axios';
import { Suspense, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './slices/index';
import { useDispatch } from 'react-redux';
import { setCategories, setProducts } from './slices/paramsSlice';

function App() {
  return (
    <Suspense>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <Routes />
        </Router>
      </PersistGate>
    </Suspense>
  );
}

export default App;
