import ReactPlayer from 'react-player';
import * as S from './styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAnimation } from 'react-type-animation';
import i18next from 'i18next';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';

const DespreComp = () => {
  const [isMuted, setIsMuted] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <S.Despre>
      <div>
        <ReactPlayer
          url={i18next?.language === 'ru' ? 'video_ru.mp4' : 'video_ro.mp4'}
          playing={true}
          loop={true}
          muted={isMuted ? false : true}
        />
        {!isMuted ? (
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={() => setIsMuted(true)}>
            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <path
                d='M16 9.50009L21 14.5001M21 9.50009L16 14.5001M4.6 9.00009H5.5012C6.05213 9.00009 6.32759 9.00009 6.58285 8.93141C6.80903 8.87056 7.02275 8.77046 7.21429 8.63566C7.43047 8.48353 7.60681 8.27191 7.95951 7.84868L10.5854 4.69758C11.0211 4.17476 11.2389 3.91335 11.4292 3.88614C11.594 3.86258 11.7597 3.92258 11.8712 4.04617C12 4.18889 12 4.52917 12 5.20973V18.7904C12 19.471 12 19.8113 11.8712 19.954C11.7597 20.0776 11.594 20.1376 11.4292 20.114C11.239 20.0868 11.0211 19.8254 10.5854 19.3026L7.95951 16.1515C7.60681 15.7283 7.43047 15.5166 7.21429 15.3645C7.02275 15.2297 6.80903 15.1296 6.58285 15.0688C6.32759 15.0001 6.05213 15.0001 5.5012 15.0001H4.6C4.03995 15.0001 3.75992 15.0001 3.54601 14.8911C3.35785 14.7952 3.20487 14.6422 3.10899 14.4541C3 14.2402 3 13.9601 3 13.4001V10.6001C3 10.04 3 9.76001 3.10899 9.54609C3.20487 9.35793 3.35785 9.20495 3.54601 9.10908C3.75992 9.00009 4.03995 9.00009 4.6 9.00009Z'
                stroke='#ffffff'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'></path>{' '}
            </g>
          </svg>
        ) : (
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={() => setIsMuted(false)}>
            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <path
                d='M16.0004 9.00009C16.6281 9.83575 17 10.8745 17 12.0001C17 13.1257 16.6281 14.1644 16.0004 15.0001M18 5.29177C19.8412 6.93973 21 9.33459 21 12.0001C21 14.6656 19.8412 17.0604 18 18.7084M4.6 9.00009H5.5012C6.05213 9.00009 6.32759 9.00009 6.58285 8.93141C6.80903 8.87056 7.02275 8.77046 7.21429 8.63566C7.43047 8.48353 7.60681 8.27191 7.95951 7.84868L10.5854 4.69758C11.0211 4.17476 11.2389 3.91335 11.4292 3.88614C11.594 3.86258 11.7597 3.92258 11.8712 4.04617C12 4.18889 12 4.52917 12 5.20973V18.7904C12 19.471 12 19.8113 11.8712 19.954C11.7597 20.0776 11.594 20.1376 11.4292 20.114C11.239 20.0868 11.0211 19.8254 10.5854 19.3026L7.95951 16.1515C7.60681 15.7283 7.43047 15.5166 7.21429 15.3645C7.02275 15.2297 6.80903 15.1296 6.58285 15.0688C6.32759 15.0001 6.05213 15.0001 5.5012 15.0001H4.6C4.03995 15.0001 3.75992 15.0001 3.54601 14.8911C3.35785 14.7952 3.20487 14.6422 3.10899 14.4541C3 14.2402 3 13.9601 3 13.4001V10.6001C3 10.04 3 9.76001 3.10899 9.54609C3.20487 9.35793 3.35785 9.20495 3.54601 9.10908C3.75992 9.00009 4.03995 9.00009 4.6 9.00009Z'
                stroke='#fff'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'></path>{' '}
            </g>
          </svg>
        )}
      </div>
      <div className='content'>
        <div className='first_row'>
          <div>
            <h1>
              {' '}
              <TypeAnimation
                sequence={[
                  t('Despre Phomi'), // Types 'One'
                  0,
                ]}
                key={i18next.language}
                wrapper='span'
                cursor={true}
                repeat={Infinity}
                style={{
                  fontSize: '64px',
                  display: 'inline-block',
                  lineHeight: '64px',
                  fontWeight: '700',
                  minHeight: '32px',
                }}
              />
            </h1>
            <p className='italic'>“{t('zicala')}“</p>
          </div>
          <img
            src='/Phomi_Logo_Black_Green_Trasnparent_Background.png'
            alt='logo'
          />
        </div>
        <div className='bloc'>
          <img src='/despre1.jpeg' alt='Despre Phomi' />

          <div>
            <p>{t('despre_1')}</p>
            <p>{t('despre_2')}</p>
            <p>{t('despre_3')}</p>
            <p>{t('despre_4')}</p>
          </div>
        </div>
        <div className='bloc'>
          <div>
            <p>{t('despre_5')}</p>
            <p>{t('despre_6')}</p>
            <p>{t('despre_7')}</p>
            <p>{t('despre_8')}</p>
            <p>{t('despre_9')}</p>{' '}
          </div>
          <img src='/despre3.jpg' alt='Despre Phomi' />
        </div>
      </div>
      <div className='beneficii'>
        <div>
          <h2>{t('beneficii')}</h2>
          <p>{t('beneficii_desc')}</p>
          <div>
            <div>
              <h3>
                <img src='/3-removebg-preview.png' alt='Phomi' />
                {t('flex_title')}
              </h3>
              <p>{t('flex_desc')}</p>
            </div>
            <div>
              <h3>
                <img src='/9-removebg-preview.png' alt='Phomi' />
                {t('dur_title')}
              </h3>
              <p>{t('dur_desc')}</p>
            </div>
            <div>
              <h3>
                <img src='/5-removebg-preview.png' alt='Phomi' />
                {t('inghet_title')}
              </h3>
              <p>{t('inghet_desc')}</p>
            </div>
            <div>
              <h3>
                <img src='/13-removebg-preview.png' alt='Phomi' />
                {t('foctu_title')}
              </h3>
              <p>{t('foctu_desc')}</p>
            </div>
            <div>
              <h3>
                <img src='/17-removebg-preview.png' alt='Phomi' />
                {t('usor_title')}
              </h3>
              <p>{t('usor_desc')}</p>
            </div>
            <div>
              <h3>
                <img src='/12-removebg-preview.png' alt='Phomi' />
                {t('apa_title')}
              </h3>
              <p>{t('apa_desc')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='swiper_comp'>
        <Swiper
          // install Swiper modules
          modules={[Autoplay, Pagination]}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3500,
          }}
          breakpoints={{
            346: {
              // width: 576,
              slidesPerView: 1,
            },
            768: {
              // width: 768,
              slidesPerView: 1,
            },
          }}>
          <SwiperSlide>
            <img src='/swiper4.jpeg' />
          </SwiperSlide>
          <SwiperSlide>
            <img src='/swiper1.jpeg' />
          </SwiperSlide>
          <SwiperSlide>
            <img src='/swiper2.jpeg' />
          </SwiperSlide>
          <SwiperSlide>
            <img src='/swiper3.webp' />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='history'>
        <div>
          <h3>{t('istoria_title')}</h3>
          <p>{t('istoria_1')}</p>
          <p>{t('istoria_2')}</p>
          <p>{t('istoria_3')}</p>
          <p>{t('istoria_4')}</p>
          <div>
            <Link to='/caracteristici-tehnice'>
              {t('Caracteristici Tehnice')}
            </Link>
            <Link to='/produse'>{t('produse_phomi')}</Link>
          </div>
        </div>
        <img src='/side3.jpeg' alt='' />
      </div>
    </S.Despre>
  );
};

export default DespreComp;
