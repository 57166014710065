import { Link } from 'react-router-dom';
import ReactAnimation from '../BgAnimated';
import QuestionBlock from './QuestionBlock';
import * as S from './styles';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Hero = () => {
  const { t, i18n } = useTranslation();
  return (
    <S.Hero isRussian={i18next.language === 'ru'}>
      <ReactAnimation />
      <div className='content'>
        <div>
          <h1
            style={{
              fontSize: i18next.language === 'ro' ? '48px' : '40px',
              display: 'inline-block',
              lineHeight: '50px',
              fontWeight: '700',
              minHeight: '100px',
            }}>
            {t('Hero Title')}
          </h1>
          <p>{t('hero_desc')}</p>
          <div className='buttons'>
            <Link
              to='#'
              onClick={() => {
                document
                  .getElementById('galerie')
                  .scrollIntoView({ behavior: 'smooth' });
              }}>
              {t('see_products')}
            </Link>
            <Link
              to='#'
              onClick={() => {
                document
                  .getElementById('contact')
                  .scrollIntoView({ behavior: 'smooth' });
              }}>
              {t('contact_us')}
            </Link>
          </div>
        </div>
        <QuestionBlock />
      </div>
    </S.Hero>
  );
};

export default Hero;
