import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import paramsSlice from './paramsSlice';

const paramPersistConfig = {
  key: 'params',
  storage,
};

const rootReducer = combineReducers({
  params: persistReducer(paramPersistConfig, paramsSlice),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export let persistor = persistStore(store);
