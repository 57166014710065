import Header from './components/Header';
import Footer from './components/Footer';
import Favorites from './components/Favorites';
import Caracteristici from './components/Caracteristici';

const CaracteristiciPage = () => {
  return (
    <>
      <Header />
      <Caracteristici />
      <Footer />
    </>
  );
};

export default CaracteristiciPage;
