import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const getSavedLanguage = () => {
  const savedLang = localStorage.getItem('i18nextLang');
  return savedLang || 'ro'; // Default to Romanian if no language is saved
};

i18n
  .use(HttpApi) // Use HttpApi for loading translations
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // Configuring i18next to load translations from public/translations/{lng}/home.json
    backend: {
      loadPath: '/translations/{{lng}}/home.json',
    },
    lng: getSavedLanguage(), // Set default language to Romanian
    fallbackLng: 'ro', // Use English if the translation is not available
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
