import { useEffect, useState } from 'react';
import * as S from './styles';
import { Link } from 'react-router-dom';
import Contact from '../Contact';
import Product from '../ProduseHero/product';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [storageChange, setStorageChange] = useState(0); // New state to trigger re-render
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleFavoritesUpdate = () => {
      // Retrieve the favorites from sessionStorage
      const storedFavorites =
        JSON.parse(sessionStorage.getItem('favorites')) || [];
      // Set the retrieved favorites to the state
      setFavorites(storedFavorites);
    };

    // Set up event listener for custom event
    window.addEventListener('favoritesUpdated', handleFavoritesUpdate);

    // Perform initial load of favorites
    handleFavoritesUpdate();

    // Clean up event listener
    return () => {
      window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
    };
  }, []);

  const product = useSelector((state) => state.params.products);

  const category = useSelector((state) => state.params.categories);

  const history = useHistory();
  return (
    <>
      <S.Favorites>
        <h1>{t('produs_favorite')}</h1>
        <p>{t('find_prod')}</p>
        <div>
          {favorites?.length >= 1 ? (
            <div className='produse'>
              {favorites?.map((elem) => (
                <Product
                  data={elem.data}
                  categoryData={
                    category.filter(
                      (categ) => categ?.category_name === elem?.data?.category
                    )?.[0]
                  }
                  onClick={() => {
                    history.push(
                      `/produs/${elem.data?.category}/${elem.data?.name.replace(
                        '/',
                        ''
                      )}`
                    );
                  }}
                />
              ))}
            </div>
          ) : (
            <div className='no_products'>
              <h2>{t('nu_prod_fav')}</h2>
              <Link to='/produse'>{t('explor_prods')}</Link>
            </div>
          )}
        </div>
      </S.Favorites>
      <Contact fromFavorites />
    </>
  );
};

export default Favorites;
