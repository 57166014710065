import { useEffect, useState } from 'react';
import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const DecePhomi = () => {
  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNav, setOpenNav] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <S.Whyus id='de-ce-noi'>
      <div>
        <h2>{t('de_ce_aleg')}</h2>
        <div>
          <p>{t('de_ce_para')}</p>
          <a
            href={`/Catalog Phomi.pdf`}
            download='Catalog Phomi.pdf'
            target='_blank'>
            {t('descarc_catalog')}
          </a>
          {windowWidth > 768 ? (
            <Fade>
              <div className='blocks'>
                <div>
                  <h4>{t('univers')}</h4>
                  <p>{t('univers_desc')}</p>
                </div>
                <div>
                  <h4>{t('usurinta')}</h4>
                  <p>{t('usurinta_desc')}</p>
                </div>
                <div>
                  <h4>{t('variatie')}</h4>
                  <p>{t('variaties_desc')}</p>
                </div>
                <div>
                  <h4>{t('rezistent_foc')}</h4>
                  <p>{t('rezistent_desc')}</p>
                </div>
                <div>
                  <h4>{t('ecologic')}</h4>
                  <p>{t('ecologic_desc')}</p>
                </div>
                <div>
                  <h4>{t('montaj')}</h4>
                  <p>{t('montaj_desc')}</p>
                </div>
                <div>
                  <h4>{t('adaption')}</h4>
                  <p>{t('adaption_desc')}</p>
                </div>
                <div>
                  <h4>{t('durabil')}</h4>
                  <p>{t('durabil_desc')}</p>
                </div>
              </div>
            </Fade>
          ) : (
            <Swiper
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              className='mySwiper'
              spaceBetween={12}
              slidesPerView={4}
              autoplay={{
                delay: 2000,
              }}
              breakpoints={{
                346: {
                  // width: 576,
                  slidesPerView: 1,
                },
                500: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 4,
                },
              }}>
              <SwiperSlide>
                <div>
                  <h4>{t('univers')}</h4>
                  <p>{t('univers_desc')}</p>
                </div>
                <div>
                  <h4>{t('usurinta')}</h4>
                  <p>{t('usurinta_desc')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>{t('variatie')}</h4>
                  <p>{t('variaties_desc')}</p>
                </div>
                <div>
                  <h4>{t('rezistent_foc')}</h4>
                  <p>{t('rezistent_desc')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>{t('ecologic')}</h4>
                  <p>{t('ecologic_desc')}</p>
                </div>
                <div>
                  <h4>{t('montaj')}</h4>
                  <p>{t('montaj_desc')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>{t('adaption')}</h4>
                  <p>{t('adaption_desc')}</p>
                </div>
                <div>
                  <h4>{t('durabil')}</h4>
                  <p>{t('durabil_desc')}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          )}
        </div>
        <Fade>
          <div className='images'>
            <div>
              <img src='/why1.jpeg' alt='why1' />
            </div>
            <div>
              <img src='/why2.jpeg' alt='why1' />
            </div>
            <div>
              <img src='/why3.webp' alt='why1' />
            </div>
            <div>
              <img src='/why4.jpeg' alt='why1' />
            </div>
          </div>
        </Fade>
      </div>
    </S.Whyus>
  );
};

export default DecePhomi;
