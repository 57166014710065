import styled from 'styled-components';

export const ProductModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  & > div {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px 32px;
    border: 4px solid #fff;
    border-top-width: 32px;
    border-bottom-width: 20px;
    position: relative;
    ${({ inProgress }) => inProgress && `overflow: hidden;`}
    @media (max-width: 768px) {
      padding: 23px 10px;
      border-top-width: 16px;
    }
    .close {
      position: absolute;
      top: 0px;
      right: 20px;
      cursor: pointer;
      @media (max-width: 768px) {
        right: 0px;
        top: -10px;
      }
    }
    .content {
      h1 {
        position: relative;
        &::after {
          position: absolute;
          width: 100px;
          height: 5px;
          content: '';
          bottom: 5px;
          background: linear-gradient(to right, #212121, #1bb9a4);
          left: 0;
        }
        span {
          font-size: 32px;
          color: blue;
          /* Create a linear gradient from black to blue */
          background: linear-gradient(to right, #212121, #1bb9a4);
          /* Clip the background so it is only behind the text */
          -webkit-background-clip: text;
          background-clip: text;
          /* Make the text color transparent, so the gradient is visible */
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          font-size: 46px;
          margin-bottom: 18px;
          position: relative;
          width: fit-content;
          @media (max-width: 768px) {
            font-size: 32px !important;
            margin-bottom: 8px;
          }
          @media (max-width: 390px) {
            font-size: 35px !important;
          }
        }
      }
      .subcontent {
        font-size: 18px;
        margin-top: 6px;
        font-weight: 300;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .products_wrapper {
        display: flex;
        align-items: flex-start;
        gap: 48px;
        margin-top: 64px;
        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: 32px;
          gap: 12px;
        }
        .selected_categ {
          padding: 4px 12px;
          background: #1bb9a3;
          color: #fff;
          border-radius: 6px;
          display: flex;
          align-items: center;
          svg {
            max-width: 20px;
            width: 15px;
            height: 15px;
            margin-left: 12px;
          }
        }
        .sidebar {
          max-width: 30%;
          width: 100%;
          display: flex;
          flex-direction: column;
          position: sticky;
          top: 0px;
          left: 0;
          height: fit-content;
          @media (max-width: 768px) {
            max-width: 100%;
            position: relative;
            top: unset;
            left: unset;
          }
          h3 {
            font-weight: 300;
            margin-bottom: 32px;
            font-size: 28px;
            scroll-margin-top: 160px;
            @media (max-width: 768px) {
              scroll-margin-top: 80px;
            }
            @media (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 12px;
              font-size: 18px;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border-radius: 4px;
              margin-bottom: 7px;
            }
            svg {
              display: none;
              @media (max-width: 768px) {
                display: flex;
                max-width: 30px;
                &.active {
                  transform: rotate(180deg);
                }
              }
            }
          }
          .category {
            div {
              display: flex;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              padding: 4px 8px;
              transition: 0.3s ease-in-out;
              border-radius: 6px;
              cursor: pointer;
              border: 1px solid transparent;
              &:hover {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border: 1px solid #1bb9a3;
                p {
                  color: #1bb9a3;
                }
              }
              &.active {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border: 1px solid #1bb9a3;
                p {
                  color: #1bb9a3;
                }
              }
              p {
                font-size: 18px;
                color: #3e3935;
                cursor: pointer;
                transition: 0.3s ease-in-out;
              }
              span {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d8f1ed;
                color: #3e3935;
                border-radius: 50%;
                font-size: 14px;
              }
            }
          }
        }
        .products {
          max-width: 70%;
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 12px;
          margin-top: 8px;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          & > div {
            max-width: calc(33% - 6px);
            @media (max-width: 1000px) {
              max-width: calc(50% - 6px);
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
