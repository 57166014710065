import ReactPlayer from 'react-player';
import * as S from './styles';
import { TypeAnimation } from 'react-type-animation';
import { Fade } from 'react-reveal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
const Caracteristici = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const certificates = [
    {
      img: '3d-TECH heavy metal formaldehyde test SGS_page-0001.jpg',
      name: '3d-TECH heavy metal formaldehyde test SGS_page-0001',
    },
    {
      img: 'A2 EU (EN) Reaction on Fire_page-0001.jpg',
      name: 'A2 EU (EN) Reaction on Fire_page-0001',
    },
    {
      img: 'engl_version.png',
      name: 'Product Carbon Footprint Verification',
    },
    {
      img: 'Antifungal antibateria test results_page-0001.jpg',
      name: 'Antifungal antibateria test results_page-0001',
    },
    {
      img: 'CE - MCM for the Walls Cladding_page-0001.jpg',
      name: 'CE - MCM for the Walls Cladding_page-0001',
    },
    {
      img: 'Declaration of Performance of PHOMI MCM_page-0001.jpg',
      name: 'Declaration of Performance of PHOMI MCM_page-0001',
    },
    {
      img: '15.jpg',
      name: 'Declaration of Performance of PHOMI MCM_page-0001',
    },
    {
      img: 'Green Label (Singapore 2017)_page-0001.jpg',
      name: 'Green Label (Singapore 2017)_page-0001',
    },
    {
      img: 'GREENGUARD GOLD Certificate_page-0001.jpg',
      name: 'GREENGUARD GOLD Certificate_page-0001',
    },
    {
      img: 'ISO9001（Quality Management System）_page-0001.jpg',
      name: 'ISO9001（Quality Management System）_page-0001',
    },
    {
      img: 'ISO14001 （Environmental Management System）_page-0001.jpg',
      name: 'ISO14001 （Environmental Management System）_page-0001',
    },
    {
      img: 'ISO45001 (Occupational Health & Safety Management System）_page-0001.jpg',
      name: 'ISO45001 (Occupational Health & Safety Management System）_page-0001',
    },
    {
      img: 'PHOMI material test by SGS (7 characteristics) 2016_page-0001.jpg',
      name: 'PHOMI material test by SGS (7 characteristics) 2016_page-0001',
    },
    {
      img: 'REACH - SVHC Test Report_page-0001.jpg',
      name: 'REACH - SVHC Test Report_page-0001',
    },
    {
      img: 'rep 14-03091-2_page-0001.jpg',
      name: 'rep 14-03091-2_page-0001',
    },
    // {
    //   img: 'Surface Burning Characteristics Test_page-0001.jpg',
    //   name: 'Surface Burning Characteristics Test_page-0001',
    // },
    {
      img: 'Termal conductivity & Resistance and Toxicity Certificate_page-0001.jpg',
      name: 'Termal conductivity & Resistance and Toxicity Certificate_page-0001',
    },
    {
      img: 'Tests set of SGS (Termal & Toxicity limits tests)_page-0001.jpg',
      name: 'Tests set of SGS (Termal & Toxicity limits tests)_page-0001',
    },
    {
      img: 'VCM & HM & Thermal Contactivity & Termal Resistance Test Report (1)_page-0001.jpg',
      name: 'VCM & HM & Thermal Contactivity & Termal Resistance Test Report (1)_page-0001',
    },
    {
      img: 'VOC Test Report_page-0001.jpg',
      name: 'VOC Test Report_page-0001',
    },
    {
      img: 'VOC-_page-0001.jpg',
      name: 'VOC-_page-0001',
    },
  ];

  return (
    <S.Caracteristici isRussian={i18next.language === 'ru'}>
      <h2>
        <TypeAnimation
          key={i18next.language}
          sequence={[
            t('caracter_te'), // Types 'One'
            0,
          ]}
          wrapper='span'
          cursor={true}
          repeat={Infinity}
          style={{
            fontSize: '48px',
            display: 'inline-block',
            lineHeight: '50px',
            fontWeight: '700',
            minHeight: '32px',
          }}
        />
      </h2>
      <p>{t('caracter_desc')}</p>
      <div>
        <Fade left>
          <div className='table'>
            <h3 style={{ marginTop: '0' }}>{t('indicat_teh')}</h3>
            <div>
              <div>
                <p>{t('densit_su')}</p>
                <p>1,73</p>
              </div>
              <div>
                <p>{t('resist_trac')}</p>
                <p>6,3 MPa</p>
              </div>
              <div>
                <p>{t('resist_rupere')}</p>
                <p>26,6 kN/m</p>
              </div>
            </div>
            <h3>{t('propriet_fiz')}</h3>
            <div>
              <div>
                <p>{t('coef1')}</p>
                <p>{t('din_masa')}</p>
              </div>
              <div>
                <p>{t('imper')}</p>
                <p>{t('min_fara')}</p>
              </div>
              <div>
                <p>{t('rezist_inge')}</p>
                <p>{t('rezist_inge_desc')}</p>
              </div>
              <div>
                <p>{t('rezist_cond')}</p>
                <p>{t('rezist_desc')}</p>
              </div>
              <div>
                <p>{t('rezist_foc')}</p>
                <p>{t('rezist_foc_desc')}</p>
              </div>
              <div>
                <p>{t('rezist_abraz')}</p>
                <p>{t('abraz_desc')}</p>
              </div>
              <div>
                <p>{t('coef_frecare')}</p>
                <p>0,07</p>
              </div>
              <div>
                <p>{t('coefa_adere')}</p>
                <p>{t('adere_desc')}</p>
              </div>
              <div>
                <p>{t('elastic')}</p>
                <p>{t('elastic_desc')}</p>
              </div>
            </div>
            <h3>{t('props_chim')}</h3>
            <div>
              <div>
                <p>{t('rezist_incarca')}</p>
                <p>{t('incarc_desc')}</p>
              </div>
              <div>
                <p>{t('rezist_coriz')}</p>
                <p>{t('coriz_desc')}</p>
              </div>
            </div>
          </div>
        </Fade>

        <Fade right>
          <div className='video'>
            <ReactPlayer url='/IMG_0307.MP4' controls />
          </div>
        </Fade>
      </div>
      <h3>{t('certif_title')}</h3>
      <PhotoProvider>
        <div className='certificate'>
          <Fade>
            <Swiper
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              className='mySwiper'
              spaceBetween={12}
              slidesPerView={4}
              slidesPerGroup={2}
              autoplay={{
                delay: 2000,
              }}
              breakpoints={{
                346: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 4,
                },
              }}>
              {certificates.map((elem, i) => (
                <SwiperSlide>
                  <div className='content_swiper'>
                    {/* <h3>{elem?.name}</h3> */}
                    <PhotoView src={`/certificates/${elem?.img}`}>
                      <img
                        src={`/certificates/${elem?.img}`}
                        alt={elem?.name}
                        style={{ cursor: 'pointer' }}
                      />
                    </PhotoView>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Fade>
        </div>
      </PhotoProvider>
    </S.Caracteristici>
  );
};

export default Caracteristici;
