import OutsideClickHandler from 'react-outside-click-handler';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';

AWS.config.update({
  region: 'eu-west-2', // e.g., 'us-east-1'
  accessKeyId: 'AKIAU6ZT5WTRGNHM3FGE',
  secretAccessKey: 'GYswcfE8w9jspkzD+7RMToYA/X0XugNbPgsa7Kn7',
});

const EditCateg = ({ categData, onClose, setMessage }) => {
  const [fileList, setFileList] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [newData, setNewData] = useState({ ...categData });
  const [fileInput, setFileInput] = useState();

  const uploadFileToS3 = async (file) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: 'phomi',
      Key: `${categData.category_path?.replace(
        'https://phomi.s3.eu-west-2.amazonaws.com/',
        ''
      )}/category.jpg`,
      Body: file,
    };
    setFileInput(file);
    try {
      const data = await s3.upload(params).promise();
      if (fileList.length >= 1) {
        setFileList([...fileList, data]);
      } else {
        setFileList([data]);
      }
      setUploadedFile(`${data.Location}?timestamp=${new Date().getTime()}`);
      // Optionally, you can perform actions after successful upload
    } catch (error) {
      // Handle errors
    }
  };

  const submit = () => {
    axios
      .put(
        `https://server-phomi.onrender.com/api/categories/${categData?.category_id}`,
        {
          ...newData,
        }
      )
      .then(() => {
        setMessage('Categoria a fost editata cu success!');
        onClose();
        console.log('Success');
      });
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      uploadFileToS3(file);
    }
  };

  // Function to open file input when clicking on container
  const handleContainerClick = () => {
    fileInputRef.current.click();
  };
  return (
    <S.EditCateg>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div>
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='close'
            width={35}
            height={35}
            onClick={onClose}>
            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                fill='#212121'></path>{' '}
            </g>
          </svg>
          <h3>Editeaza Categorie</h3>
          <div>
            <p>Numele Categoriei</p>
            <input
              type='text'
              value={newData.category_name}
              onChange={(e) =>
                setNewData({ ...newData, category_name: e.target.value })
              }
            />
            <span>_ </span>
          </div>
          <div>
            <p>Dimensiunea 1</p>
            <input
              type='text'
              value={newData.dimension_1}
              onChange={(e) =>
                setNewData({ ...newData, dimension_1: e.target.value })
              }
            />
            <span>Pastrati acelasi format (MM "x" MM)</span>
          </div>
          <div>
            <p>Dimensiunea 2 (optional)</p>
            <input
              type='text'
              value={newData.dimension_2}
              onChange={(e) =>
                setNewData({ ...newData, dimension_2: e.target.value })
              }
            />
            <span>Pastrati acelasi format (MM "x" MM)</span>
          </div>
          <div>
            <p>Pret Dimensiunea 1</p>
            <input
              type='text'
              value={newData.price_small}
              onChange={(e) =>
                setNewData({ ...newData, price_small: e.target.value })
              }
            />
            <span>Pastrati acelasi format (CIFRE)</span>
          </div>
          <div>
            <p>Pret Dimensiunea 2</p>
            <input
              type='text'
              value={newData.price_big}
              onChange={(e) =>
                setNewData({ ...newData, price_big: e.target.value })
              }
            />
            <span>Pastrati acelasi format (CIFRE)</span>
          </div>
          <div>
            <p>Imagine Categoriei</p>
            <div className='image'>
              {uploadedFile ? (
                <img src={uploadedFile} alt='Category' className='big_img' />
              ) : (
                <img
                  src={`${newData?.category_path}/category.jpg`}
                  alt='Category'
                  className='big_img'
                />
              )}
              <input
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
                multiple=''
                type='file'
                aria-hidden='true'
                accept='application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/heic'
                id='field-:r3:'
                className='chakra-input css-1h4ycrt'
              />
              <p onClick={handleContainerClick}>Incarca alta imagine</p>
            </div>
          </div>
          <div className='button'>
            <button onClick={() => submit()}>Aplica Schimbarile</button>
          </div>
        </div>
      </OutsideClickHandler>
    </S.EditCateg>
  );
};

export default EditCateg;
