import { useTranslation } from 'react-i18next';
import * as S from './styles';

const Steps = () => {
  const { t, i18n } = useTranslation();
  return (
    <S.Steps>
      <div className='container'>
        <div className='row row-obtii'>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>{t('select')}</span>
              <br />
              {t('first_select')}
              <br />
              {t('second_select')}
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>{t('specific')}</span>
              <br />
              {t('specific_first')}
              <br />
              {t('specific_second')}
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>{t('confirm')}</span>
              <br />
              {t('confirm_first')}
              <br /> {t('confirm_second')}
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links' style={{ width: 2000 }}>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>{t('primeste')}</span>
              <br />
              {t('primeste_first')}
              <br />
              {t('primeste_second')}
            </div>
          </div>
        </div>
      </div>
    </S.Steps>
  );
};

export default Steps;
