import styled, { css } from 'styled-components';

const helperSlidesInAnimation = css`
  @keyframes helper-slides-in {
    0% {
      top: -100px;
    }

    25% {
      top: 40px;
    }

    100% {
      top: 40px;
    }
  }

  -webkit-animation: helper-slides-in 4s 1; /* Safari 4+ */
  -moz-animation: helper-slides-in 4s 1; /* Fx 5+ */
  -o-animation: helper-slides-in 4s 1; /* Opera 12+ */
  animation: helper-slides-in 4s 1; /* IE 10+, Fx 29+ */
`;
export const Success = styled.p`
  ${helperSlidesInAnimation}
  padding: 12px 16px;
  border-radius: 8px;
  background: #1bb9a4;
  color: #fff;
  font-size: 14px;
  padding-right: 60px;
  position: relative;
  margin-left: auto;
  margin-top: -20px;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: top 0.2s, opacity 0.1s;
  z-index: 999999999900000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  br {
    display: none;
  }
  @media (max-width: 768px) {
    max-width: 360px;
    width: 100%;
    font-size: 13px;
    br {
      display: block;
    }
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 25px;
  }
`;

export const Cerere = styled.div`
  margin-top: 24px;
  padding: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  margin-bottom: -20px;
  margin-top: 64px;
  @media (max-width: 1220px) {
    margin: 0 15px;
  }
  @media (max-width: 768px) {
    padding: 16px;
  }
  h5 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
    color: #3e3935;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .block_individual {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    transition: 0.3s ease-in-out;
    @media (max-width: 768px) {
      margin-bottom: 24px;
      z-index: 3;
      position: relative;
      margin-bottom: 0;
    }
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
    svg {
      max-width: 25px;
      width: 100%;
    }
    p {
      font-size: 20px;
      color: #3e3935;
      position: relative;
      font-weight: 500;
    }
  }
  button {
    font-size: 14px;
    border-radius: 8px;
    border: none;
    padding: 8px 16px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: 0.3s ease-in-out;
    background: #1bb9a4;
    width: 150px;
    margin-top: auto;
    &:hover {
      cursor: pointer;
      background: #169482;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;
      flex-direction: column;
      position: relative;
      max-width: 50%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      &:first-of-type::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background: #ccc;
        right: -32px;
        top: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }
      & > div {
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
          color: #3e3935;
        }
        input {
          padding: 6px 8px;
          border: none;
          border-bottom: 1px solid #ccc;
          width: 100%;
          font-size: 18px;
          background: transparent;
          transition: 0.3s ease-in-out;
          &.red_border {
            border-bottom: 1px solid red !important;
          }
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            outline: none;
            border: none;
            border-bottom: 1px solid #212121;
          }
        }
      }
    }
    & > div:last-of-type {
      padding-left: 72px;
      @media (max-width: 768px) {
        padding-left: 0;
        margin-top: 32px;
      }
    }
  }
`;
export const ProductComp = styled.div`
  max-width: 1170px;
  margin: 164px auto 24px;
  display: flex;
  align-items: flex-start;
  gap: 48px;
  @media (max-width: 1220px) {
    padding: 0 15px;
    margin-top: 64px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 18px;
  }
  .left-side {
    max-width: 40%;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      &:first-of-type {
        height: 500px;
        @media (max-width: 768px) {
          height: 350px;
          margin-top: 16px;
        }
      }
    }
  }
  .right-side {
    max-width: 57%;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    h1 {
      color: blue;
      /* Create a linear gradient from black to blue */
      background: linear-gradient(to right, #212121, #1bb9a4);
      /* Clip the background so it is only behind the text */
      -webkit-background-clip: text;
      background-clip: text;
      /* Make the text color transparent, so the gradient is visible */
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      font-size: 32px;
      margin-bottom: 0px;
      position: relative;
      line-height: 50px;
      width: fit-content;
      font-weight: 400;
      position: relative;
      width: 100%;
      svg {
        position: absolute;
        max-width: 30px;
        right: 0;
        top: 10px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
      }
      .share {
        right: 40px;
        max-width: 35px;
        top: 6px;
      }
      .big_heart {
        max-width: 34px;
        width: 100%;
        top: 6px;
        right: -2px;
      }
      span {
        font-size: 48px;
        font-weight: 700;
        @media (max-width: 768px) {
          font-size: 42px;
        }
      }
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
    .img_price {
      margin: 18px 0;
      & > img {
        max-width: 300px;
        width: 100%;
        border-radius: 4px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        @media (max-width: 768px) {
          max-width: 100%;
          width: 100%;
          height: 150px;
        }
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 32px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
      }
      h3 {
        position: relative;
        &::after {
          position: absolute;
          width: 100%;
          height: 2px;
          content: '';
          bottom: 0px;
          background: linear-gradient(to right, #212121, #1bb9a4);
          left: 0;
        }
        b {
          font-weight: 600;
          font-size: 16px;
          position: relative;
          top: -12px;
        }
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 400;
      color: #3e3935;
      margin-bottom: 0px;
      span {
        font-weight: 600;
      }
    }
    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      & > div {
        max-width: calc(50% - 12px);
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 768px) {
          width: 100%;
          max-width: 100%;
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          ${({ isRussian }) =>
            isRussian && `font-weight: 600; font-size: 14px;`}
        }
        p {
          font-size: 13px;
        }
        img {
          max-width: 50px;
          margin-bottom: -2px;
        }
      }
    }
    .supkk {
      margin-top: 8px;
    }
    .options {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin-top: 10px;
      margin-bottom: 20px;
      p {
        max-width: calc(50% - 8px);
        width: 100%;
        text-align: center;
        padding: 10px 12px;
        border: 1px solid #1bb9a4;
        border-radius: 6px;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        &.active {
          background: #1bb9a4;
          color: #fff;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const Similar = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  margin-bottom: 32px;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  .swiper {
    padding-bottom: 32px;
  }
  .swiper-pagination-bullet {
    width: 30px;
    border-radius: 2px;
    height: 2px;
    background: #1bb9a4;
    @media (max-width: 768px) {
      display: none;
    }
  }
  & > h3 {
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 46px;
    margin-bottom: 32px;
    position: relative;
    width: fit-content;
    &::after {
      position: absolute;
      width: 100px;
      height: 5px;
      content: '';
      bottom: 0px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 34px;
    }
  }
`;
