import styled, { keyframes } from 'styled-components';

export const About = styled.div`
  scroll-margin-top: 130px;
  @media (max-width: 768px) {
    scroll-margin-top: 70px;
  }
  & > div:first-of-type {
    max-width: 1170px;
    margin-top: 32px;
    margin: 48px auto;
    margin-bottom: 82px;
    @media (max-width: 1210px) {
      padding: 0 15px;
    }
    & > img {
      max-width: 100%;
      width: 100%;
      border-radius: 48px;
      height: 300px;
    }
    & > div:first-of-type {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 10px;
      gap: 64px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
      }
      & > div {
        &:first-child {
          max-width: 65%;
          width: 100%;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          h2 {
            color: blue;
            /* Create a linear gradient from black to blue */
            background: linear-gradient(to right, #212121, #1bb9a4);
            /* Clip the background so it is only behind the text */
            -webkit-background-clip: text;
            background-clip: text;
            /* Make the text color transparent, so the gradient is visible */
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
            font-size: 56px;
            margin-bottom: 18px;
            position: relative;
            width: fit-content;
            &::after {
              position: absolute;
              width: 100px;
              height: 5px;
              content: '';
              bottom: 0px;
              background: linear-gradient(to right, #212121, #1bb9a4);
              right: 81px;
              @media (max-width: 768px) {
                left: 0;
                bottom: -6px;
              }
            }
            @media (max-width: 768px) {
              font-size: 38px;
            }
          }
          p {
            color: rgb(37, 37, 37);
            margin-bottom: 16px;
          }
          .blocks {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 24px;
            & > div {
              max-width: calc(50% - 8px);
              width: 100%;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border-radius: 8px;
              padding: 16px;
              background: #fff;
              height: auto;
              @media (max-width: 600px) {
                max-width: 100%;
              }
              h3 {
                color: blue;
                /* Create a linear gradient from black to blue */
                background: linear-gradient(to right, #212121, #1bb9a4);
                /* Clip the background so it is only behind the text */
                -webkit-background-clip: text;
                background-clip: text;
                /* Make the text color transparent, so the gradient is visible */
                -webkit-text-fill-color: transparent;
                text-fill-color: transparent;
                font-size: 24px;
                margin-bottom: 8px;
                line-height: 28px;
                display: flex;
                align-items: center;
                img,
                svg {
                  max-width: 35px;
                  margin-right: 10px;
                }
                svg {
                  max-width: 28px;
                  width: 28px;
                  height: 28px;
                }
              }
              p {
                color: rgb(37, 37, 37);
                margin-bottom: 0;
              }
              /* &:nth-of-type(even) {
                position: relative;
                bottom: -8px;
              } */
            }
          }
        }
        &:last-child {
          max-width: 35%;
          width: 100%;
          height: auto;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          img {
            max-width: 100%;
            height: calc(50% - 4px);
            width: 100%;
            object-fit: cover;
            object-position: center;
            @media (max-width: 768px) {
              height: 300px;
            }
            &:first-child {
              border-radius: 8px 8px 0 0;
            }
            &:last-child {
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }
    }
  }
  .bg_parallax {
    height: 360px;
    background-image: url(banner_long.jpeg);
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
  }
`;
